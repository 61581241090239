<template>
    <v-app class="ib-app main-color">
        <app-confirm-dialog />

        <v-main v-if="$route.meta.layout === 'fullscreen'">
            <router-view />
        </v-main>

        <dashboard-layout v-if="$route.meta.layout === 'menu'" />

        <pastdue-payment-dialog v-if="currentUser?._id" />
    </v-app>
</template>

<script>
import Api from "@/lib/Api";
let socket;
let socket_connection_count = 0;
import { emitToastr } from "./lib/Utils";

export default {
    name: "App",
    components: {
        AppConfirmDialog: () => import("@/components/AppConfirmDialog.vue"),
        PastduePaymentDialog: () => import("@/components/PastduePaymentDialog.vue"),
        DashboardLayout: () => import("@/layouts/DashboardLayout.vue"),
    },

    data: () => ({
        drawer: false,
        projectName: "InteligênciaBot",
    }),

    computed: {
        pageLoading() {
            return this.$store.state.pageLoading
        },
        currentUser() {
            return this.$store.state.currentUser
        },
        openConversations() {
            return this.$store.state.conversations.open_manual_count;
        }
    },
    watch: {
        '$route': {
            immediate: true,
            handler: async function () {
                this.$store.commit('setBreadcrumbs', [])
                this.$store.commit('setPageTitle', null)
                this.$store.commit('setPageSubtitle', null)

                if (this.currentUser) return

                if (this.$route.meta.layout === 'fullscreen') return
                if (this.$route.path === '/login' || this.$route.path === '/cadastro') return

                await this.$store.dispatch('loadCurrentUser');

            },
        }
    },

    created() {
        this.$root.$refs.global = this;
    },

    async mounted() {
        this.$vuetify.breakpoint.lgAndUp ? (this.drawer = true) : (this.drawer = false);
        this.$root.$refs.showTips = this.showTips;

        const storage_user = await Api.getUser();

        if (storage_user) {
            await this.$store.dispatch('loadCurrentUser');
            if (this.currentUser?._id) {
                this.$store.dispatch('conversations/load_open_manual_count');
                /*
                // INÍCIO DO FEEDELIZE
                let user_role = 'Atendente';
                if (this.currentUser.user_role === 'owner') user_role = 'Dono';
                if (this.currentUser.user_role === 'manager') user_role = 'Gerente';

                let tags = [user_role];

                if (this.currentUser.account?.max_channels) tags.push(`${this.currentUser.account?.max_channels} chips`);
                if (this.currentUser.account?.max_users) tags.push(`${this.currentUser.account?.max_users} atendentes`);

                // eslint-disable-next-line
                feedelize = {
                    project_id: 'ib',
                    user_id: this.currentUser._id,
                    user_name: this.currentUser.name,
                    user_email: this.currentUser.email,
                    tags: tags
                };

                setTimeout(() => {
                    const script = document.createElement('script');
                    script.src = "https://app.feedelize.com.br/widget.js";
                    script.async = true;
                    document.body.appendChild(script);
                }, 1500);

                // FIM DO FEEDELIZE
                */
            }


            this.startSocket();
        }
    },

    methods: {
        logout() {
            Api.logout();
        },

        startSocket() {
            if (socket_connection_count >= 3) {
                emitToastr("error", "Erro na conexão com o socket. Atualize a página para tentar novamente.");
                return;
            }

            if (!this.$store.state.SOCKET_IO_URL) {
                socket_connection_count++
                setTimeout(() => { this.startSocket(); }, 500 + socket_connection_count * 2000);
                emitToastr("info", "Aguardando endereço do socket...");
                return;
            }

            socket_connection_count = 0;
            socket = this.$io(this.$store.state.SOCKET_IO_URL, { path: '/sock/' });

            socket.on("connect", () => {
                socket.emit("join-room", `notifications_${this.currentUser?.account?._id}`);
            });

            socket.on("receive-message", data => {
                if (data.event === "load_open_conversations") {
                    //this.$store.dispatch('conversations/load_open_manual_count');
                    if (this.$route.name == 'Dashboard') this.$store.dispatch('realtime/load');
                }

                if (data.event === "load_realtime") {
                    if (this.$route.name == 'Dashboard') this.$store.dispatch('realtime/load');
                }

            });
        },

        myCustomCallback() {
            setTimeout(() => {
                this.drawer = true;
            }, 200);
        }
    },


};
</script>

<style scoped>
.main-color {
    background-color: var(--v-gray25-base) !important;
}
</style>
