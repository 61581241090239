<template>
  <div v-if="currentUser?.system_user_roles?.includes('financial_dashboard')" class="menu-page">
    <span class="section-title mb-2">
      <v-icon size="18" class="mr-2">mdi-account-multiple-outline</v-icon>
      Base atual
    </span>
    <v-row>
      <v-col cols="12" md="4" lg="4" sm="12" xs="12">
        <v-card class="mx-auto pt-4 pb-4 nd-border" flat>
          <v-card-title>
            <v-icon class="icon" color="primary" left>
              mdi-account-multiple-outline
            </v-icon>
            <v-spacer />
            <div right class="div-text">
              <v-row>
                <span class="text-h3 font-weight-light">
                  <animated-number :value="real_time.active" round="1" :duration="600" />
                </span>
              </v-row>
              <v-row>
                <span class="subtitle-1">Ativos</span>
              </v-row>
            </div>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" lg="4" sm="12" xs="12">
        <v-card class="mx-auto pt-4 pb-4 nd-border" flat>
          <v-card-title>
            <v-icon class="icon" color="amber" left>
              mdi-alert-box-outline
            </v-icon>
            <v-spacer />
            <div right class="div-text">
              <v-row>
                <span class="text-h3 font-weight-light">
                  <animated-number :value="real_time.pastdue" round="1" :duration="600" />
                </span>
              </v-row>
              <v-row>
                <span class="subtitle-1">Em atraso</span>
              </v-row>
            </div>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" lg="4" sm="12" xs="12">
        <v-card class="mx-auto pt-4 pb-4 nd-border" flat>
          <v-card-title>
            <v-icon class="icon" color="error" left>
              mdi-close-circle-outline
            </v-icon>
            <v-spacer />
            <div right class="div-text">
              <v-row>
                <span class="text-h3 font-weight-light">

                  <animated-number :value="real_time.canceled" round="1" :duration="600" />
                </span>
              </v-row>
              <v-row>
                <span class="subtitle-1">Cancelados</span>
              </v-row>
            </div>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>

    <span class="section-title mt-4 mb-2">
      <v-icon size="18" class="mr-2">mdi-currency-usd</v-icon>
      Novas vendas
    </span>
    <v-row>
      <v-col cols="12" md="4" lg="4" sm="12" xs="12">
        <v-card class="mx-auto pt-1 pb-1 nd-border" flat>
          <v-card-title>
            <v-icon class="icon" color="primary" left>
              mdi-account-plus-outline
            </v-icon>
            <v-spacer />
            <div right class="div-text">
              <v-row>
                <span class="text-h3 font-weight-light">
                  <animated-number :value="sell_status.today" round="1" :duration="600" />
                </span>
              </v-row>
              <v-row>
                <span class="subtitle-1">Hoje</span>
              </v-row>
            </div>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" lg="4" sm="12" xs="12">
        <v-card class="mx-auto pt-1 pb-1 nd-border" flat>
          <v-card-title>
            <v-icon class="icon" color="primary" left>
              mdi-account-plus-outline
            </v-icon>
            <v-spacer />
            <div right class="div-text">
              <v-row>
                <span class="text-h3 font-weight-light">
                  <animated-number :value="sell_status.week" round="1" :duration="600" />
                </span>
              </v-row>
              <v-row>
                <span class="subtitle-1">7 dias</span>
              </v-row>
            </div>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" lg="4" sm="12" xs="12">
        <v-card class="mx-auto pt-1 pb-1 nd-border" flat>
          <v-card-title>
            <v-icon class="icon" color="primary" left>
              mdi-account-plus-outline
            </v-icon>
            <v-spacer />
            <div right class="div-text">
              <v-row>
                <span class="text-h3 font-weight-light">
                  <animated-number :value="sell_status.month" round="1" :duration="600" />
                </span>
              </v-row>
              <v-row>
                <span class="subtitle-1">30 dias</span>
              </v-row>
            </div>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>

    <span class="section-title mt-4 mb-2">
      <v-icon size="18" class="mr-2">mdi-cash-multiple</v-icon>
      Faturamento
    </span>
    <v-row>
      <v-col cols="12" md="4" lg="4" sm="12" xs="12">
        <v-card class="mx-auto pt-1 pb-1 nd-border" flat>
          <v-card-title>
            <div class="card-div">
              <v-icon class="icon" color="primary" left>
                mdi-currency-usd
              </v-icon>
              <div class="text-subtitle-1">
                Hoje
              </div>
            </div>
            <v-spacer />
            <div right class="div-text">
              <v-row>
                <span class="card-value">
                  {{ $utils.formatNumber(sell_income.today.new_clients + sell_income.today.old_clients - (refunds.today.total || 0), 'R$') }}
                </span>
              </v-row>
              <v-row>
                <span class="subtitle-2">
                  <animated-number :value="sell_income.today.new_clients" round="1" :format-value="formatToPrice" :duration="600" /> - Novos
                </span>
              </v-row>
              <v-row>
                <span class="subtitle-2">
                  <animated-number :value="sell_income.today.old_clients" round="1" :format-value="formatToPrice" :duration="600" /> - Recorrências
                </span>
              </v-row>
              <v-row>
                <span class="subtitle-2 red--text">
                  <animated-number :value="refunds.today.total" round="1" :format-value="formatToPrice" :duration="600" /> - Reembolsos
                </span>
              </v-row>
            </div>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" lg="4" sm="12" xs="12">
        <v-card class="mx-auto pt-1 pb-1 nd-border" flat>
          <v-card-title>
            <div class="card-div">
              <v-icon class="icon" color="primary" left>
                mdi-currency-usd
              </v-icon>
              <div class="text-subtitle-1">
                7 dias
              </div>
            </div>
            <v-spacer />
            <div right class="div-text">
              <v-row>
                <span class="card-value">
                  {{ $utils.formatNumber(sell_income.week.new_clients + sell_income.week.old_clients - (refunds.week.total || 0), 'R$') }}
                </span>
              </v-row>
              <v-row>
                <span class="subtitle-2">
                  <animated-number :value="sell_income.week.new_clients" round="1" :format-value="formatToPrice" :duration="600" /> - Novos
                </span>
              </v-row>
              <v-row>
                <span class="subtitle-2">
                  <animated-number :value="sell_income.week.old_clients" round="1" :format-value="formatToPrice" :duration="600" /> - Recorrências
                </span>
              </v-row>
              <v-row>
                <span class="subtitle-2 red--text">
                  <animated-number :value="refunds.week.total" round="1" :format-value="formatToPrice" :duration="600" /> - Reembolsos
                </span>
              </v-row>
            </div>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" lg="4" sm="12" xs="12">
        <v-card class="mx-auto pt-1 pb-1 nd-border" flat>
          <v-card-title>
            <div class="card-div">
              <v-icon class="icon" color="primary" left>
                mdi-currency-usd
              </v-icon>
              <div class="text-subtitle-1">
                30 dias
              </div>
            </div>
            <v-spacer />
            <div right class="div-text">
              <v-row>
                <span class="card-value">
                  {{ $utils.formatNumber(sell_income.month.new_clients + sell_income.month.old_clients - (refunds.month.total || 0), 'R$') }}
                </span>
              </v-row>
              <v-row>
                <span class="subtitle-2">
                  <animated-number :value="sell_income.month.new_clients" round="1" :format-value="formatToPrice" :duration="600" /> - Novos
                </span>
              </v-row>
              <v-row>
                <span class="subtitle-2">
                  <animated-number :value="sell_income.month.old_clients" round="1" :format-value="formatToPrice" :duration="600" /> - Recorrências
                </span>
              </v-row>
              <v-row>
                <span class="subtitle-2 red--text">
                  <animated-number :value="refunds.month.total" round="1" :format-value="formatToPrice" :duration="600" /> - Reembolsos
                </span>
              </v-row>
            </div>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>

    <span class="section-title mt-4 mb-2">
      <v-icon size="18" class="mr-2">mdi-finance</v-icon>
      Projeções
    </span>
    <v-row>
      <v-col cols="12" md="4" lg="4" sm="12" xs="12">
        <v-card class="mx-auto pt-1 pb-1 nd-border" flat>
          <v-card-title>
            <div class="card-div">
              <v-icon class="icon" color="primary" left>
                mdi-currency-usd
              </v-icon>
              <div class="text-subtitle-1">
                Hoje
              </div>
            </div>
            <v-spacer />
            <div right class="div-text">
              <v-row style="display: flex; flex-wrap: wrap; flex-direction: column; align-items: end;">
                <div class="card-value">
                  {{ $utils.formatNumber(sell_income.two_weeks.average, 'R$') }}
                </div>
                <v-chip v-if="sell_income.today.projection_diff" label small class="ml-2" :color="`${sell_income.today.projection_diff >= 0 ? 'success' : 'error'}`">{{ sell_income.today.projection_diff.toFixed(1) + '%' }}</v-chip>
              </v-row>
            </div>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" lg="4" sm="12" xs="12">
        <v-card class="mx-auto pt-1 pb-1 nd-border" flat>
          <v-card-title>
            <div class="card-div">
              <v-icon class="icon" color="primary" left>
                mdi-currency-usd
              </v-icon>
              <div class="text-subtitle-1">
                7 dias
              </div>
            </div>
            <v-spacer />
            <div right class="div-text">
              <v-row style="display: flex; flex-wrap: wrap; flex-direction: column; align-items: end;">
                <div class="card-value">
                  {{ $utils.formatNumber(sell_income.two_weeks.average * 7, 'R$') }}
                </div>
                <v-chip v-if="sell_income.week.projection_diff" label small class="ml-2" :color="`${sell_income.week.projection_diff >= 0 ? 'success' : 'error'}`">{{ sell_income.week.projection_diff.toFixed(1) + '%' }}</v-chip>
              </v-row>
            </div>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" lg="4" sm="12" xs="12">
        <v-card class="mx-auto pt-1 pb-1 nd-border" flat>
          <v-card-title>
            <div class="card-div">
              <v-icon class="icon" color="primary" left>
                mdi-currency-usd
              </v-icon>
              <div class="text-subtitle-1">
                30 dias
              </div>
            </div>
            <v-spacer />
            <div right class="div-text">
              <v-row style="display: flex; flex-wrap: wrap; flex-direction: column; align-items: end;">
                <div class="card-value">
                  {{ $utils.formatNumber(sell_income.two_weeks.average * 30, 'R$') }}
                </div>
                <v-chip v-if="sell_income.month.projection_diff" label small class="ml-2" :color="`${sell_income.month.projection_diff >= 0 ? 'success' : 'error'}`">{{ sell_income.month.projection_diff.toFixed(1) + '%' }}</v-chip>
              </v-row>
            </div>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>

    <span class="section-title mt-4 mb-2">
      <v-icon size="18" class="mr-2">mdi-chart-bar</v-icon>
      Relatórios
    </span>

    <v-row>
      <v-col cols="12">
        <v-card class="nd-border" flat :loading="loading">
          <v-card-title>
            Tipos de venda
          </v-card-title>
          <v-card-text :class="`${loading ? 'blur' : ''}`">
            <div id="chart4">
              <apexChart ref="chart4" height="450" :type="charts_type" :options="chart4Options" :series="series4" />
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card class="nd-border" flat :loading="loading">
          <v-card-title>
            Faturamento mensal consolidado
          </v-card-title>
          <v-card-text :class="`${loading ? 'blur' : ''}`">
            <div id="chart9">
              <apexChart ref="chart9" height="450" :type="charts_type" :options="chart9Options" :series="series9" />
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mt-4">
      <v-col cols="12">
        <v-card class="nd-border" flat :loading="loading">
          <v-card-title>
            Faturamento diário - 60 dias
          </v-card-title>
          <v-card-text :class="`${loading ? 'blur' : ''}`">
            <div id="chart4">
              <apexChart ref="chart5" height="450" :type="charts_type" :options="chart5Options" :series="series5" />
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mt-4">
      <v-col cols="12">
        <v-card class="nd-border" flat :loading="loading">
          <v-card-title>
            Receita média (14d)
          </v-card-title>
          <v-card-text :class="`${loading ? 'blur' : ''}`">
            <div id="chart6">
              <apexChart ref="chart6" height="450" type="area" :options="chart6Options" :series="series6" />
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mt-4">
      <v-col cols="12">
        <v-card class="nd-border" flat :loading="loading">
          <v-card-title>
            Projeção de faturamento mensal
          </v-card-title>
          <v-card-text :class="`${loading ? 'blur' : ''}`">
            <div id="chart7">
              <apexChart ref="chart7" height="450" type="area" :options="chart7Options" :series="series7" />
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mt-4">
      <v-col cols="12">
        <v-card class="nd-border" flat :loading="loading">
          <v-card-title>
            Distribuição de ciclos - 30 dias
          </v-card-title>
          <v-card-text :class="`${loading ? 'blur' : ''}`">
            <div id="chart4">
              <apexChart ref="chart3" height="450" :type="charts_type" :options="chart3Options" :series="series3" />
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>


    <v-row class="mt-4">
      <v-col cols="12">
        <v-card class="nd-border" flat :loading="loading">
          <v-card-title>
            Contas ativas
          </v-card-title>
          <v-card-text :class="`${loading ? 'blur' : ''}`">
            <div id="chart8">
              <apexChart ref="chart8" height="450" type="area" :options="chart8Options" :series="series8" />
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mt-4">
      <v-col cols="12">
        <v-card class="nd-border" flat :loading="loading">
          <v-card-title>
            Cancelamentos
          </v-card-title>
          <v-card-text :class="`${loading ? 'blur' : ''}`">
            <div id="chart4">
              <apexChart ref="chart1" height="450" :type="charts_type" :options="chart1Options" :series="series1" />
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";
import AnimatedNumber from "animated-number-vue";

export default {
  name: "DashboardView",

  components: {
    AnimatedNumber
  },

  data() {
    return {
      loading: false,
      real_time: { active: 0, pastdue: 0, canceled: 0 },
      sell_status: {
        today: 0,
        week: 0,
        month: 0,
      },
      sell_income: {
        today: { new_clients: 0, old_clients: 0, total: 0, projection_diff: 1 },
        week: { new_clients: 0, old_clients: 0, total: 0, projection_diff: 1 },
        month: { new_clients: 0, old_clients: 0, total: 0, projection_diff: 1 },
        two_weeks: { new_clients: 0, old_clients: 0, total: 0 },
      },
      refunds: {
        today: { total: 0 },
        week: { total: 0 },
        month: { total: 0 }
      },
      series1: [],
      series2: [],
      series3: [],
      series4: [],
      series5: [],
      series6: [],
      series7: [],
      series8: [],
      series9: [],
      charts_type: 'bar',

      chart1Options: {
        chart: {
          stacked: true,
          zoom: {
            enabled: true
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              enabled: true,
              total: {
                enabled: true,
                formatter: function (w) {
                  return w;
                },
                style: {
                  fontSize: '13px',
                  fontWeight: 900
                }
              }
            },
          }
        },
        dataLabels: {
          enabled: true
        },
        colors: ['#e53935'],
        legend: {
          position: 'top',
          fontSize: '16px',
        },
        stroke: {
          curve: 'smooth'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
          categories: [""],
          convertedCatToNumeric: false
        }
      },

      chart3Options: {
        grid: { show: false },
        chart: {
          zoom: {
            enabled: true
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 10
          }
        },
        dataLabels: {
          enabled: true
        },
        legend: {
          position: 'top',
          fontSize: '16px',
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          categories: [""],
          convertedCatToNumeric: false
        }
      },

      chart4Options: {
        grid: { show: false },
        chart: {
          stacked: true,
          zoom: {
            enabled: true
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              enabled: true,
              total: {
                enabled: true,
                formatter: function (w) {
                  return w;
                },
                style: {
                  fontSize: '13px',
                  fontWeight: 900
                }
              }
            },
          }
        },
        dataLabels: {
          enabled: true,
        },
        colors: ['#26e7a6', '#26a0fc'],
        legend: {
          position: 'top',
          fontSize: '16px',
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          categories: [""],
          convertedCatToNumeric: false
        }
      },

      chart5Options: {
        grid: { show: false },
        chart: {
          stacked: true,
          zoom: {
            enabled: true
          }
        },
        colors: ['#4caf50'],
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              enabled: true,
              total: {
                enabled: true,
                formatter: function (w) {
                  if (w) {
                    w = (w / 1000).toFixed(1)
                  }
                  return `${w || 0}k`;
                },
                style: {
                  fontSize: '13px',
                  fontWeight: 900
                }
              }
            },
          }
        },
        dataLabels: {
          enabled: false,

        },
        legend: {
          position: 'top',
          fontSize: '16px',
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          categories: [""],
          convertedCatToNumeric: false
        }
      },

      chart6Options: {
        grid: { show: false },
        chart: {
          stacked: true,
          zoom: {
            enabled: true
          }
        },
        colors: ['#4caf50'],
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              enabled: true,
              total: {
                enabled: true,
                formatter: function (w) {
                  if (w) {
                    w = (w / 1000).toFixed(1)
                  }
                  return `${w || 0}k`;
                },
                style: {
                  fontSize: '13px',
                  fontWeight: 900
                }
              }
            },
          }
        },
        dataLabels: {
          enabled: false,

        },
        legend: {
          position: 'top',
          fontSize: '16px',
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          categories: [""],
          convertedCatToNumeric: false
        }
      },

      chart7Options: {
        grid: { show: false },
        chart: {
          stacked: true,
          zoom: {
            enabled: true
          }
        },
        colors: ['#4caf50'],
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              enabled: true,
              total: {
                enabled: true,
                formatter: function (w) {
                  if (w) {
                    w = (w / 1000).toFixed(1)
                  }
                  return `${w || 0}k`;
                },
                style: {
                  fontSize: '13px',
                  fontWeight: 900
                }
              }
            },
          }
        },
        dataLabels: {
          enabled: false,

        },
        legend: {
          position: 'top',
          fontSize: '16px',
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          categories: [""],
          convertedCatToNumeric: false
        }
      },

      chart8Options: {
        grid: { show: false },
        chart: {
          zoom: {
            enabled: true
          }
        },
        colors: ['#4caf50'],
        dataLabels: {
          enabled: false,
        },
        legend: {
          position: 'top',
          fontSize: '16px',
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          categories: [""],
          convertedCatToNumeric: false
        }
      },

      chart9Options: {
        grid: { show: false },
        chart: {
          stacked: false,
          zoom: {
            enabled: true
          }
        },
        plotOptions: {
          bar: {
            columnWidth: '90%',
            borderRadius: 10,
            dataLabels: {
              enabled: true,

              total: {
                enabled: true,
                style: {
                  fontSize: '12px',
                  fontWeight: 900
                }
              }
            },
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (value) {
            if (value >= 1000) {
              return (value / 1000).toFixed(1) + 'k';
            }
            // Para números menores, retorna o próprio valor
            return value;
          },
        },
        colors: ['#26e7a6', '#26a0fc', '#ff786a'],
        legend: {
          position: 'top',
          fontSize: '16px',
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          categories: [""],
          convertedCatToNumeric: false
        }
      },

    };
  },

  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    }
  },

  async mounted() {
    this.loading = true;
    const resp = await Api.getFinancialDashboardData();
    this.loading = false;

    if (resp && resp.error) {
      return emitToastr("error", resp.message)
    }

    if (resp && resp.message) {
      this.real_time = resp.message.real_time;

      setTimeout(() => {
        this.sell_status = resp.message.sell_status;
        this.sell_income = resp.message.sell_income;
        this.refunds = resp.message.refunds;

        this.sell_income.two_weeks.average = this.sell_income.two_weeks.total / 14;

        this.sell_income.today.projection_diff = 100 - ((this.sell_income.today.total / this.sell_income.two_weeks.average) * 100);

        this.sell_income.week.average = this.sell_income.week.total / 7;
        this.sell_income.week.projection_diff = 100 - ((this.sell_income.week.average / this.sell_income.two_weeks.average) * 100);

        this.sell_income.month.average = this.sell_income.month.total / 30;
        this.sell_income.month.projection_diff = 100 - ((this.sell_income.month.average / this.sell_income.two_weeks.average) * 100);
      }, 500);

      if (resp.message.canceled_chart) {
        let cat = [];
        let canceled_series = { name: "Cancelados", data: [] };

        resp.message.canceled_chart.forEach(i => {
          cat.push(i._id);
          canceled_series.data.push(i.canceled);
        });

        this.series1 = [canceled_series];
        this.chart1Options.xaxis.categories = cat;
        this.$refs.chart1.refresh();
      }

      if (resp.message.active_accounts_chart) {
        let cat = [];
        let serie1 = { name: "Contas ativas", data: [] };

        resp.message.active_accounts_chart.forEach(i => {
          cat.push(i.reference_date.substring(0, 10));
          serie1.data.push(i.value);
        });

        this.series8 = [serie1];
        this.chart8Options.xaxis.categories = cat;
        this.$refs.chart8.refresh();
      }

      if (resp.message.cycle_chart) {
        let cat = [];
        let cycle_series = { name: "Ciclo", data: [] };

        resp.message.cycle_chart.forEach(i => {
          cat.push(i._id);
          cycle_series.data.push(i.all);
        });

        this.series3 = [cycle_series];
        this.chart3Options.xaxis.categories = cat;
        this.$refs.chart3.refresh();
      }

      if (resp.message.sell_type_chart) {
        let cat = [];
        let serie1 = { name: "Novas vendas", data: [] };
        let serie2 = { name: "Recorrências", data: [] };

        resp.message.sell_type_chart.forEach(i => {
          cat.push(i._id);
          serie1.data.push(i.new);
          serie2.data.push(i.old);
        });

        this.series4 = [serie1, serie2];
        this.chart4Options.xaxis.categories = cat;
        this.$refs.chart4.refresh();
      }

      if (resp.message.sell_income_chart) {
        let cat = [];
        let serie1 = { name: "Novas vendas", data: [] };

        resp.message.sell_income_chart.forEach(i => {
          cat.push(i._id);
          serie1.data.push(i.income);
        });

        this.series5 = [serie1];
        this.chart5Options.xaxis.categories = cat;
        this.$refs.chart5.refresh();
      }

      if (resp.message.sell_income_chart) {
        let cat = [];
        let serie1 = { name: "Faturamento total", data: [] };
        let serie2 = { name: "Venda mensal", data: [] };
        let serie3 = { name: "Reembolso mensal", data: [] };

        resp.message.month_incomes.forEach(i => {
          cat.push(i._id);
          serie2.data.push(i.total);

          const refunds = resp.message.month_refunds.find(x => x._id === i._id);

          refunds ? serie3.data.push(refunds.total) : serie3.data.push(0);
          serie1.data.push(i.total - (refunds?.total || 0));
        });

        this.series9 = [serie1, serie2, serie3];
        this.chart9Options.xaxis.categories = cat;
        this.$refs.chart9.refresh();
      }

      if (resp.message.average_revenue_chart) {
        let cat = [];
        let serie1 = { name: "Receita média 14d", data: [] };

        resp.message.average_revenue_chart.forEach(i => {
          cat.push(i.reference_date.substring(0, 10));
          serie1.data.push(i.value);
        });

        this.series6 = [serie1];
        this.chart6Options.xaxis.categories = cat;
        this.$refs.chart6.refresh();
      }

      if (resp.message.average_revenue_chart) {
        let cat = [];
        let serie1 = { name: "Projeção de faturamento mensal", data: [] };

        resp.message.average_revenue_chart.forEach(i => {
          cat.push(i.reference_date.substring(0, 10));
          serie1.data.push((i.value * 30).toFixed(0));
        });

        this.series7 = [serie1];
        this.chart7Options.xaxis.categories = cat;
        this.$refs.chart7.refresh();
      }
    }

  },

  methods: {
    formatToPrice(value) {
      return `R$ ${value}`;
    }
  },
};
</script>

<style scoped>
.icon {
  font-size: 60px;
}

.section-title {
  display: flex;
  flex-direction: row;
  align-content: center;
  font-size: 18px;
  font-weight: 600;
  color: var(--v-gray600-base);
}

.div-text {
  margin-right: 20px;
}

.card-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-value {
  margin-bottom: 10px;
  font-size: 40px;
  font-weight: 300;
}
</style>