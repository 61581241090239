<template>
  <div class="menu-page">
    <div v-if="!currentUser.account.campaigns_enabled">
      <v-alert class="fade" text dense type="warning">
        Funcionalidade indisponível no Plano Solo. Por favor, faça upgrade para um plano superior.
        <v-btn class="ml-2" color="warning" @click.native="$router.push('/minha-conta')">
          Ver planos
          disponíveis
        </v-btn>
      </v-alert>
    </div>

    <div v-if="currentUser.account.campaigns_enabled">
      <v-alert class="fade" dense text type="error" dismissible>
        Aviso importante: a funcionalidade de campanha (envio em massa) deve ser utilizada com cautela, pois o
        envio exagerado ou o envio para audiências não qualificadas pode causar banimento do chip, uma vez que
        as pessoas podem denunciar spam. Sempre tente enviar via chips alternativos já
        "quentes", para audiências qualificadas, com bom intervalo entre as mensagens, e com variações de
        versões. Seguindo as boas práticas você poderá fazer suas campanhas com segurança.
      </v-alert>
      <v-card class="nd-border" flat>
        <v-card-title>
          Campanhas
        </v-card-title>

        <v-card-subtitle>
          Crie campanhas para enviar mensagens em massa para seus contatos. Segmente envios de fluxo ou mensagens de texto.  <ib-btn small @click="showVideo = true"><v-icon size="16" class="mr-1">mdi-help-circle-outline</v-icon>Saiba mais</ib-btn>
        </v-card-subtitle>

        <v-card-text v-if="showAlert || showAlert2">
          <v-alert v-if="showAlert" class="fade" dense text type="error" dismissible>
            Sua conta não possui nenhum número conectado. Será necessário conectar um número/chip para fazer o envio.
            <div>
              <v-btn class="mt-2" color="error" @click.native="$router.push('/chips')">
                Conectar número
              </v-btn>
            </div>
          </v-alert>
        </v-card-text>

        <v-card-text>
          <div v-if="!showAlert">
            <v-row>
              <v-col cols="12" class="d-flex">
                <v-spacer></v-spacer>
                <ib-btn color="primary" @click.native="openNew">
                  <v-icon left>mdi-plus</v-icon>
                  Nova campanha
                </ib-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table :headers="headers" :items="objects">
                  <template #[`item.created`]="{ item }">
                    {{ new Date(item.created).toLocaleString('pt-BR').replace(',', ' -') }}
                  </template>

                  <template #[`item.scheduledTo`]="{ item }">
                    {{ item.scheduledTo ? new
                      Date(item.scheduledTo).toLocaleString('pt-BR').replace(',', ' -') : 'Imediato'
                    }}
                  </template>

                  <template #[`item.status`]="{ item }">
                    {{ statuses[item.status] }}
                  </template>

                  <template #[`item.audience`]="{ item }">
                    {{ getAudience(item.audience) }}
                  </template>

                  <template #[`item.sent_messages`]="{ item }">
                    <v-tooltip v-if="item.status === 'started'" top>
                      <template #activator="{ on, attrs }">
                        <v-icon medium color="primary" v-bind="attrs" @click="selectedItem = item; openRealTime(item._id)" v-on="on">
                          mdi-eye-outline
                        </v-icon>
                      </template>
                      <span>Acompanhar em tempo real</span>
                    </v-tooltip>

                    <div v-if="item.status != 'started'">
                      {{ item.sent_messages }}
                    </div>
                  </template>


                  <template #[`item.actions`]="{ item }">
                    <v-menu>
                      <template #activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-dots-horizontal</v-icon>
                        </v-btn>
                      </template>

                      <v-card>
                        <v-list-item @click="openObject(item)">
                          <v-list-item-icon>
                            <v-icon>mdi-file-edit-outline</v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title>Editar</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item @click="duplicateObject(item)">
                          <v-list-item-icon>
                            <v-icon>mdi-content-duplicate</v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title>Duplicar</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item v-if="item.status === 'started'" @click="selectedItem = item; openRealTime(item._id)">
                          <v-list-item-icon>
                            <v-icon>mdi-eye-outline</v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title>Acompanhar em tempo real</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item v-if="!['canceled', 'finished'].includes(item.status)" @click="object = item; dialog2 = true">
                          <v-list-item-icon>
                            <v-icon>mdi-close-circle</v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title>Cancelar</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item @click="object = item; dialog = true">
                          <v-list-item-icon>
                            <v-icon>mdi-trash-can-outline</v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title>Apagar</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-card>
                    </v-menu>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>

      <ib-dialog v-model="dialogCreate" scrollable max-width="1200">
        <v-card>
          <ib-dialog-header title="Campanha" subtitle="Envie mensagens em massa para um público cadastrado." :close="() => { getObjects(); dialogCreate = false }"></ib-dialog-header>

          <v-card-text class="pt-1">
            <v-row>
              <v-col cols="12" lg="6" md="6" sm="12">
                <ib-text-field v-model="object.name" label="Nome da campanha" placeholder="Insira o nome da campanha" outlined hide-details="auto" />
              </v-col>

              <v-col cols="12" lg="6" md="6" sm="12">
                <ib-autocomplete v-model="object.chip" clearable item-text="name" item-value="_id" :items="chips" label="Chip de envio" outlined hide-details="auto" />
              </v-col>

              <v-col cols="12" lg="6" md="6" sm="12">
                <ib-autocomplete v-model="object.audience_type" clearable item-text="name" item-value="id" :items="types" label="Público de envio" outlined hide-details="auto" />
              </v-col>

              <v-col v-if="object.audience_type == 'audience'" cols="12" lg="6" md="6" sm="12">
                <ib-autocomplete v-model="object.audience" clearable item-text="name" item-value="_id" :items="audiences" label="Público ou audiência" outlined hide-details="auto" />
              </v-col>

              <v-col v-if="object.audience_type == 'tags'" cols="12" lg="6" md="6" sm="12">
                <ib-autocomplete v-model="object.audience_tags" chips small-chips multiple outlined :items="tags" item-text="name" item-value="_id" label="Enviar para quem possui qualquer uma das tags" hide-details="auto">
                  <template #selection="{ item }">
                    <ib-chip small :color="item.color">
                      {{ item.name }}
                    </ib-chip>
                  </template>
                </ib-autocomplete>
              </v-col>

              <v-col v-if="object.audience_type == 'tags'" cols="12" lg="6" md="6" sm="12">
                <ib-autocomplete v-model="object.audience_tags_skip" chips small-chips multiple outlined :items="tags" item-text="name" item-value="_id" label="NÃO enviar se possuir qualquer uma das tags" hide-details="auto">
                  <template #selection="{ item }">
                    <ib-chip small :color="item.color">
                      {{ item.name }}
                    </ib-chip>
                  </template>
                </ib-autocomplete>
              </v-col>

              <v-col cols="12" lg="6" md="6" sm="12">
                <ib-autocomplete v-model="object.message_type" clearable item-text="name" item-value="id" :items="message_types" label="Tipo de conteúdo" outlined hide-details="auto" />
              </v-col>

              <v-col v-if="object.message_type == 'flow'" cols="12" lg="6" md="6" sm="12">
                <ib-autocomplete v-model="object.flow" clearable item-text="name" item-value="_id" :items="flows" label="Fluxo" outlined hide-details="auto" />
              </v-col>

              <v-col cols="12" lg="6" md="6" sm="12">
                <ib-autocomplete v-model="object.interval" clearable :items="intervals" label="Intervalo entre mensagens (segundos)" outlined hide-details="auto" messages="Intervalos maiores são mais seguros contra bloqueios de chip" />
              </v-col>

              <v-col cols="12" lg="6" md="6" sm="12">
                <v-datetime-picker v-model="object.scheduledTo" label="Data e hora do envio" date-format="dd/MM/yyyy" :date-picker-props="{ locale: 'pt-BR' }" :time-picker-props="{ format: '24hr' }" :text-field-props="{ outlined: true, 'hide-details': 'auto' }">
                  <template slot="dateIcon">
                    <v-icon>mdi-calendar</v-icon>
                  </template>

                  <template slot="timeIcon">
                    <v-icon>mdi-clock-outline</v-icon>
                  </template>

                  <template slot="actions" slot-scope="{ parent }">
                    <ib-btn color="error lighten-1" outlined @click.native="parent.clearHandler">
                      Cancelar
                    </ib-btn>
                    <ib-btn color="success en-1" @click="parent.okHandler">
                      OK
                    </ib-btn>
                  </template>
                </v-datetime-picker>
              </v-col>
              <v-col v-if="object.message_type == 'text'" cols="12">
                <v-divider />
                <div class="content-title">
                  Conteúdo da mensagem
                </div>
                <div class="content-subtitle">
                  Mensagens do tipo texto serão enviadas, mas só criarão uma
                  nova
                  conversa na plataforma após a resposta do contato (para evitar que o chatbot
                  interfira em
                  conversas pessoais iniciadas por você diretamente no celular)
                </div>
                <div class="content-subtitle">
                  O sistema sorteará uma das versões para cada pessoa da
                  audiência.
                  Quanto mais versões, mais segurança contra bloqueios de chip
                </div>
              </v-col>

              <v-row v-if="object.message_type == 'text'">
                <v-col v-for="(text, index) in object.message" :key="index" cols="12" class="d-flex">
                  <v-textarea class="nd-text-field" v-model="object.message[index]" rows="4" :label="`Versão ${index + 1} da mensagem`" placeholder="Escreva aqui a mensagem de texto que será enviada." outlined hide-details="auto" />
                  <v-btn :disabled="index == 0" icon color="error lighten-2" class="ml-2" @click="object.message.splice(index, 1)">
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </v-col>

                <v-col cols="12">
                  <v-btn small color="primary" outlined @click="object.message.push('Insira o texto aqui')">
                    Nova
                    versão
                  </v-btn>
                </v-col>
              </v-row>
            </v-row>
          </v-card-text>

          <v-card-actions class="d-flex flex-wrap">
            <ib-btn outlined color="gray200" @click.native="getObjects(); dialogCreate = false">
              <div class="gray1000--text">
                Cancelar
              </div>
            </ib-btn>

            <v-spacer />

            <ib-btn class="my-1" color="primary" :loading="loading" @click.native="createNew">
              Salvar
            </ib-btn>
          </v-card-actions>
        </v-card>
      </ib-dialog>


      <ib-dialog v-model="dialogRealTime" scrollable max-width="1200">
        <v-card>
          <ib-dialog-header title="Campanha" subtitle="Acompanhe em tempo real o envio das campanhas em andamento." :close="() => { dialogRealTime = false }"></ib-dialog-header>

          <v-card-text class="pt-1">
            <v-row>
              <v-col cols="12">
                <v-progress-linear v-if="(real_time_data?.sent_messages)" :value="100 * real_time_data.sent_messages / (real_time_data.sent_messages + real_time_data.next_messages)" height="30" rounded>
                  <template #default="{ value }">
                    <div class="text-caption-total">
                      {{ Math.ceil(value) }}%
                    </div>
                  </template>
                </v-progress-linear>
              </v-col>

              <v-col v-if="real_time_data?.estimated_finish_time" cols="12" class="subtitle-1">
                <b>Previsão de término:</b> {{ new
                  Date(real_time_data.estimated_finish_time).toLocaleString('pt-BR') }}
              </v-col>

              <v-col v-if="last_message?.type" cols="12">
                <v-alert dense text type="success">
                  <strong>{{ last_message.to }}</strong> {{ last_message.type === 'flow' ? ' recebeu uma mensagem de fluxo!' : ` recebeu a mensagem "${last_message.text}"` }}
                </v-alert>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="d-flex flex-wrap">
            <v-spacer />
            <ib-btn class="mr-2" outlined color="gray200" @click.native="dialogRealTime = false">
              <div class="gray1000--text">
                Fechar
              </div>
            </ib-btn>
          </v-card-actions>
        </v-card>
      </ib-dialog>

      <ib-dialog v-model="dialog" overlay-opacity="0.5" overlay-color="white" max-width="350">
        <v-card>
          <ib-dialog-header title="Apagar campanha" :close="() => { dialog = false }"></ib-dialog-header>

          <v-card-text>Tem certeza que deseja apagar a campanha <b>{{ object.name }}</b>?</v-card-text>

          <v-card-actions>
            <ib-btn outlined color="gray200" @click="dialog = false;">
              <div class="gray1000--text">
                Cancelar
              </div>
            </ib-btn>

            <v-spacer />

            <ib-btn color="error" @click="dialog = false; deleteObject(object)">
              Apagar
            </ib-btn>
          </v-card-actions>
        </v-card>
      </ib-dialog>

      <ib-dialog v-model="dialog2" overlay-opacity="0.5" overlay-color="white" max-width="350">
        <v-card>
          <ib-dialog-header title="Cancelar campanha" :close="() => { dialog2 = false }"></ib-dialog-header>

          <v-card-text>Tem certeza que deseja cancelar a campanha <b>{{ object.name }}</b>?</v-card-text>
          <v-card-actions>
            <ib-btn outlined color="gray200" @click="dialog2 = false;">
              <div class="gray1000--text">
                Não
              </div>
            </ib-btn>

            <v-spacer />

            <ib-btn color="error" @click="dialog2 = false; cancelObject(object)">
              Sim, tenho certeza
            </ib-btn>
          </v-card-actions>
        </v-card>
      </ib-dialog>
    </div>

    <video-dialog v-model="showVideo" videoUrl="https://www.youtube.com/embed/64x46-3SGk4?rel=0" />
  </div>
</template>

<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";
let socket;
let socket_connection_count = 0;
import VideoDialog from "@/components/VideoDialog.vue";

export default {
  name: "CampanhasView",
  
  components: {
    VideoDialog
  },

  data() {
    return {
      objects: [],
      showVideo: false,
      object: { to: "", message: [""], scheduled: false, message_type: "text" },
      chips: [],
      audiences: [],
      flows: [],
      selectedItem: {},
      types: [
        { name: "Audiência cadastrada", id: "audience" },
        { name: "Por tags", id: "tags" },
      ],
      tags: [],
      statuses: {
        "scheduled": "Agendada",
        "error": "Erro",
        "finished": "Concluída",
        "canceled": "Cancelada",
        "started": "Iniciada",
      },
      message_types: [
        { id: 'text', name: 'Texto' },
        { id: 'flow', name: 'Fluxo' },
      ],
      intervals: [20, 30, 60, 90, 120, 180, 240],
      showAlert: false,
      showAlert2: false,
      dialog: false,
      dialog2: false,
      dialogCreate: false,
      dialogRealTime: false,
      loading: false,
      real_time_data: {},
      last_message: {},
      headers: [
        { text: 'Nome', value: 'name' },
        { text: 'Criação', value: 'created' },
        { text: 'Agendamento', value: 'scheduledTo' },
        { text: 'Público', value: 'audience' },
        { text: 'Alcance estimado', value: 'people_count' },
        { text: 'Envios realizados', value: 'sent_messages' },
        { text: "Status", value: "status", sortable: false },
        { text: "Ações", value: "actions", sortable: false }
      ]
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    }
  },

  async beforeDestroy() {
    socket.disconnect();
    socket = null;
  },

  async mounted() {
    this.getTags();
    this.startSocket();
    await this.getObjects();

    const chips_call = Api.getChipNumbersByUser();
    const audiences_call = Api.getAudienceByUser();
    const flows_call = Api.getFlowsByUser();

    const [chips, audiences, flows] = await Promise.all([chips_call, audiences_call, flows_call]);

    if (!chips.error && chips.message) {
      this.chips = chips.message.filter(c => c.status == 'open');
      if (this.chips.length < 1) this.showAlert = true;
    }

    if (!audiences.error && audiences.message) this.audiences = audiences.message;
    if (this.audiences.length < 1) this.showAlert2 = true;

    if (!flows.error && flows.message) this.flows = flows.message;
  },

  methods: {
    async getObjects() {
      const resp = await Api.getCampaignsByUser();

      if (!resp.error && resp.message) {
        this.objects = resp.message;
      }
    },

    startSocket() {
      if (socket_connection_count >= 3) {
        emitToastr("error", "Erro na conexão com o socket. Atualize a página para tentar novamente.");
        return;
      }

      if (!this.$store.state.SOCKET_IO_URL) {
        socket_connection_count++
        setTimeout(() => { this.startSocket(); }, 500 + socket_connection_count * 2000);
        emitToastr("info", "Aguardando endereço do socket...");
        return;
      }

      socket_connection_count = 0;
      socket = this.$io(this.$store.state.SOCKET_IO_URL, { path: '/sock/' });

      socket.on("connect", async () => {

      });

      socket.on("receive-message", data => {
        if (data.event === "new_message") {
          this.last_message = data;
          this.getRealTimeData();
        }
      });
    },

    async getRealTimeData() {
      const resp = await Api.getCampaignRealTimeData(this.selectedItem);

      if (!resp.error && resp.message) {
        this.real_time_data = resp.message;
      }
    },

    async getTags() {
      const resp = await Api.getTagsByUser();

      if (!resp.error && resp.message) {
        this.tags = resp.message;
      }
    },

    getAudience(audience_id) {
      return this.audiences.find(a => a._id?.toString() == audience_id?.toString())?.name || "N/A";
    },

    async createNew() {
      if (!this.object.chip) return emitToastr("error", "Chip de envio necessário");
      if (!this.object.scheduledTo) return emitToastr("error", "Data e hora de envio necessária");

      if (this.audience_type == 'audience') {
        if (!this.object.audience) return emitToastr("error", "Audiência necessária para formar o público de envio");
      }
      else {
        //TAGS
        if (!this.object.audience_tags || this.object.audience_tags.length == 0) return emitToastr("error", "Tags necessárias para formar o público de envio");
      }

      if (this.object.message_type === 'text') {
        for (const i in this.object.message) {
          if (this.object.message[i].trim() == "") return emitToastr("error", "As versões de mensagem não podem estar vazias. Escreva o texto de todas as versões.");
        }
      }

      this.loading = true;
      let resp;

      if (this.object._id) {
        resp = await Api.updateCampaign(this.object);
      }
      else {
        resp = await Api.createCampaign(this.object);
      }

      this.loading = false;

      if (!resp.error) {
        this.object = { message: [""], scheduled: false };
        this.dialogCreate = false;
        emitToastr("success", "Campanha cadastrada com sucesso.");
        setTimeout(() => {
          this.getObjects();
        }, 2000);
      }
    },

    async deleteObject(object) {
      object.filed = true;
      await Api.updateCampaign(object);
      this.getObjects();
      this.object = { message: [""], scheduled: false };
    },

    async cancelObject(object) {
      object.cancel_now = true;
      await Api.updateCampaign(object);
      this.getObjects();
      this.object = { message: [""], scheduled: false };
    },

    openNew() {
      this.object = { message: [""], scheduled: false };
      this.dialogCreate = true;
    },

    openObject(item) {
      item.scheduledTo = new Date(item.scheduledTo);
      this.object = item;
      this.dialogCreate = true;
    },

    duplicateObject(item) {
      let new_object = JSON.parse(JSON.stringify(item))

      delete new_object._id;
      delete new_object.sent_messages;
      delete new_object.people_count;
      delete new_object.status;
      delete new_object.created;
      delete new_object.finished;
      delete new_object.scheduledTo;

      this.object = new_object;
      this.dialogCreate = true;
    },

    openRealTime(campaing_id = "") {
      this.getRealTimeData();
      this.dialogRealTime = true;

      socket.emit("leave-all-rooms", ``);
      socket.emit("join-room", `account_campaign_${campaing_id.toString()}`);
    }
  },
};
</script>

<style scoped>
.content-title {
  margin-top: 8px;
  font-size: 16px;
  font-weight: 600;
}
</style>