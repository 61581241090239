<template>
  <v-navigation-drawer v-model="drawer" :mini-variant="mini" mini-variant-width="72" width="290" app class="ib-app-drawer">
    <v-list nav :dense="!mini" expand>
      <router-link text to="/" class="mt-4 mb-4 d-block" :class="mini ? 'text-center' : 'text-left'">
        <v-img src="@/assets/logo2.png" class="mx-8 mb-6"></v-img>
      </router-link>

      <template v-for="(item, index) in items">
        <div v-if="item.children && !item.hide" :key="`group_${index}`">
          <v-divider />

          <template v-if="mini">
            <div v-for="(child, childIndex) in item.children" :key="`child_${childIndex}`">
              <v-list-item v-if="!child.hide" :to="child.to" color="primary" @click="child.onClick && child.onClick()">
                <v-list-item-icon>
                  <v-icon style="width:18px;height:18px" v-text="child.icon" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ child.text }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>

          </template>

          <v-list-group v-else no-action class="my-2" color="gray500" :value="activeMenuItems.includes(index) || mini" @input="toggleMenuItem(index)">
            <template #activator>
              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <div v-for="(child, childIndex) in item.children" :key="`child_${childIndex}`">
              <v-list-item v-if="!child.hide" :to="child.to" color="primary" @click="child.onClick && child.onClick()">
                <v-list-item-icon :class="mini ? 'mx-auto' : ''">
                  <v-icon>{{ child.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ child.text }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>


          </v-list-group>

          <v-divider />
        </div>

        <v-list-item v-else-if="!item.hide" :key="`item_${index}`" :to="item.to" color="primary" @click="item.onClick && item.onClick()">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>

    <template #append>
      <v-menu v-if="currentUser" offset-y>
        <template #activator="{ on }">
          <v-list-item text v-on="on">
            <v-list-item-avatar rounded>
              <ib-user-avatar :user="currentUser" size="40" color="gray75" rounded />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="gray950--text font-weight-bold">
                {{ currentUser?.name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ currentUser?.email }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-icon>mdi-dots-vertical</v-icon>
            </v-list-item-action>
          </v-list-item>
        </template>

        <v-list>
          <v-list-item to="/meu-perfil">
            <v-list-item-title>
              <v-icon size="22" class="mr-2">
                mdi-account
              </v-icon>
              Meu cadastro
            </v-list-item-title>
          </v-list-item>

          <v-list-item v-if="currentUser?.system_user_roles?.includes('customer_admin')" to="/admin-clientes">
            <v-list-item-title>
              <v-icon size="22" class="mr-2">
                mdi-account-multiple-outline
              </v-icon>
              Administração de clientes
            </v-list-item-title>
          </v-list-item>

          <v-list-item v-if="currentUser?.system_user_roles?.includes('tech_dashboard')" to="/dash-tecnico">
            <v-list-item-title>
              <v-icon size="22" class="mr-2">
                mdi-monitor-dashboard
              </v-icon>
              Dashboard técnico
            </v-list-item-title>
          </v-list-item>

          <v-list-item v-if="currentUser?.system_user_roles?.includes('financial_dashboard')" to="/dash-financeiro">
            <v-list-item-title>
              <v-icon size="22" class="mr-2">
                mdi-finance
              </v-icon>
              Dashboard financeiro
            </v-list-item-title>
          </v-list-item>

          <v-list-item v-if="['owner'].includes(currentUser?.user_role)" to="/minha-conta">
            <v-list-item-title>
              <v-icon size="22" class="mr-2">
                mdi-chart-box-outline
              </v-icon>
              Minha assinatura
            </v-list-item-title>
          </v-list-item>

          <v-list-item @click="logout">
            <v-list-item-title>
              <v-icon size="22" class="mr-2">
                mdi-exit-run
              </v-icon>
              Sair
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>


    <v-dialog v-model="dialog" width="600" scrollable>
      <ib-changelog />
    </v-dialog>
  </v-navigation-drawer>
</template>

<script>
import Api from "@/lib/Api";

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    mini: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: false,
      activeMenuItems: []
    }
  },
  computed: {
    drawer: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    currentUser() {
      return this.$store.state.currentUser
    },
    isAdmin() {
      return this.currentUser?.admin;
    },
    items() {
      return [
        {
          icon: 'mdi-help-box-outline',
          text: 'Central de ajuda',
          to: '/ajuda'
        },
        {
          icon: 'mdi-view-dashboard-outline',
          text: 'Dashboard',
          to: '/',
          hide: !['owner', 'manager'].includes(this.currentUser?.user_role) || this.currentUser?.access_restrictions?.includes('dashboard')
        },
        {
          text: 'Contatos',
          icon: 'mdi-account-multiple-outline',
          to: '/contatos',
          hide: !['owner', 'manager', 'user'].includes(this.currentUser?.user_role) || this.currentUser?.access_restrictions?.includes('contacts')
        },
        {
          text: 'Conversas em andamento',
          icon: 'mdi-message-badge-outline',
          to: '/atendimento',
          hide: !['owner', 'manager', 'user'].includes(this.currentUser?.user_role)
        },
        {
          text: 'Conversas finalizadas',
          icon: 'mdi-message-off-outline',
          to: '/conversas',
          hide: !['owner', 'manager', 'user'].includes(this.currentUser?.user_role)
        },
        {
          text: 'Campanhas e agendamentos',
          children: [
            {
              text: 'Audiências',
              icon: 'mdi-file-account-outline',
              to: '/audiencias',
            },
            {
              text: 'Campanhas',
              icon: 'mdi-message-fast-outline',
              to: '/campanhas',
            },
            {
              text: 'Agendamento individual',
              icon: 'mdi-calendar-clock',
              to: '/agendamento',
            }
          ]
        },
        {
          text: 'Configurações',
          hide: !['owner', 'manager'].includes(this.currentUser?.user_role),
          children: [
            {
              text: 'Meus chips',
              icon: 'mdi-whatsapp',
              to: '/chips',
            },
            {
              text: 'Chatbots de I.A',
              icon: 'mdi-robot-outline',
              to: '/chatbots',
            },
            {
              text: 'Chatbots de fluxo',
              icon: '$flow',
              to: '/flows',
            },
            {
              text: 'Minha equipe',
              icon: 'mdi-account-plus-outline',
              to: '/equipe',
              hide: this.currentUser?.access_restrictions?.includes('team')
            },
            {
              text: 'Tags ou rótulos',
              icon: 'mdi-tag-multiple-outline',
              to: '/tags',
            },
            {
              text: 'Departamentos',
              icon: 'mdi-briefcase-outline',
              to: '/departamentos',
            },
            {
              text: 'Mensagens rápidas',
              icon: 'mdi-message-text-fast-outline',
              to: '/mensagens-rapidas',
            }
          ]
        },
        {
          text: 'Desenvolvedores',
          children: [
            {
              text: 'Integrações',
              icon: 'mdi-api',
              to: '/api',
              hide: !['owner', 'manager'].includes(this.currentUser?.user_role)
            },
            {
              text: `Versão 3.2`,
              icon: 'mdi-information-outline',
              onClick: () => this.dialog = true
            }

          ]
        },
      ]
    }
  },
  watch: {
    activeMenuItems: {
      deep: true,
      handler(value) {
        localStorage.setItem('app:drawer:active-menu-items', JSON.stringify(value));
      },
    }
  },
  mounted() {
    const cache = localStorage.getItem('app:drawer:active-menu-items');

    if (cache) {
      this.activeMenuItems = JSON.parse(cache);
    }
  },

  methods: {
    openLink(link) {
      window.open(link, "_blank");
    },
    logout() {
      Api.logout();
    },

    toggleMenuItem(index) {
      if (this.activeMenuItems.includes(index)) {
        this.activeMenuItems = this.activeMenuItems.filter(item => item !== index);
      } else {
        this.activeMenuItems.push(index);
      }
    },

  },

}
</script>

<style scoped>
.ib-app-drawer {
  /* Fallback para navegadores que não reconhecem o fill-available */
  max-height: 100vh;

  /* Tenta preencher disponível no Safari */
  max-height: -webkit-fill-available;

  /* Tenta usar as unidades dinâmicas (browsers modernos) */
  max-height: 100svh;
}

::v-deep .v-navigation-drawer__content {
  scrollbar-width: thin !important;
  scrollbar-color: rgba(206, 206, 206, 0.322) transparent !important;
}

.v-navigation-drawer__content .ib-app-drawer {
  .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: var(--v-gray500-base);
  }

  &.v-navigation-drawer--mini-variant {
    .v-list-item__icon {
      margin: 0 !important;
      height: inherit;
      min-height: inherit;
      width: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
