<template>
  <div class="flow-single">
    <v-toolbar flat>
      <v-btn to="/flows" icon exact>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <div>
        <v-text-field v-model="flow.name" dense class="mt-6" outlined placeholder="Nome do fluxo" />
      </div>

      <v-spacer />

      <v-btn color="primary" outlined :loading="loading" class="mr-2" @click="load">
        Redefinir
      </v-btn>

      <v-btn color="primary" :loading="saving" @click="save">
        Salvar
      </v-btn>
    </v-toolbar>
    <v-divider />
    <div class="flow-canvas">
      <v-progress-linear v-if="loading" indeterminate color="primary" />

      <flow-editor v-else ref="editor" v-model="flow" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FlowSingle',
  components: {
    FlowEditor: () => import('@/flow-editor/FlowEditor.vue')
  },
  data() {
    return {
      loading: false,
      saving: false,
      flow: {
        name: 'Loading...',
        nodes: [],
      }
    }
  },
  computed: {
    flowId() {
      return this.$route.params.flowId
    }
  },
  created() {
    this.load()
  },
  methods: {
    async load() {
      this.loading = true

      const response = await this.$api.getFlowById(this.flowId)

      if (response.error) {
        this.$router.replace('/flows')
        return
      }

      this.flow = response.message

      setTimeout(() => {
        this.loading = false
      }, 400);
    },
    async save() {
      if (document.activeElement) {
        document.activeElement.blur();
      }

      if (!this.$refs.editor.validate()) {
        this.$toast("error", "Existem erros no fluxo")
        return
      }

      this.saving = true

      setTimeout(async () => {
        const response = await this.$api.createOrUpdateFlow(this.flow)

        if (response.error) {
          return
        }

        this.$toast("success", "Fluxo salvo com sucesso")
        this.saving = false
      }, 1000);
    }
  }
}
</script>

<style>
.flow-single .flow-canvas {
  width: 100dvw;
  height: calc(100dvh - 65px);
  background-color: #e6f5ea;
}
</style>