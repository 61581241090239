<template>
  <div class="custom-page" @click="emojiDialog = false">
    <div class="d-flex mt-2 px-4 align-center justify-space-between">

      <ib-btn class="primary" :small="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs" @click="filter_sheet = true">
        <v-icon left>mdi-filter-outline</v-icon>
        Buscar ou filtrar
      </ib-btn>

      <v-spacer></v-spacer>
      <ib-btn class="primary mr-2" :small="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs" @click="startNewConversationDialog">
        <v-icon left>mdi-plus</v-icon>
        Nova conversa
      </ib-btn>


      <v-menu offset-y>
        <template #activator="{ on }">
          <v-btn class="primary" dark icon :small="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs" v-on="on">
            <v-icon>mdi-cog-outline</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item class="cursor-pointer" @click="toggleNotificationSoundConfig(currentUser.config?.disable_notification_sound)">
            <v-list-item-title>
              <v-icon size="25" class="mr-1" color="primary">
                mdi-volume-high
              </v-icon>
              {{ currentUser.config?.disable_notification_sound ? 'Ativar notificações' : 'Desativar notificações' }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item class="cursor-pointer" @click="dialogFinishConversations = true" v-if="currentUser.user_role === 'owner'">
            <v-list-item-title>
              <v-icon size="25" class="mr-1" color="primary">
                mdi-forum-remove-outline
              </v-icon>
              Finalizar em massa
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>


    </div>

    <v-row style="margin: 0;">
      <v-col cols="12" lg="5" md="5" sm="12" class="pa-0">
        <div class="mx-4 mb-4">
          <v-expansion-panels class="nd-border" flat v-if="can_clear">
            <v-expansion-panel>
              <v-expansion-panel-header style="font-weight: 500;">Existem filtros ativos</v-expansion-panel-header>
              <v-expansion-panel-content class="py-1">
                <div class="filter-bar">
                  <div class="filter-item one-line">
                    <b>Atendimento:</b> <span>{{ getSelectedTypes }}</span>
                  </div>
                  <div class="filter-item one-line">
                    <b>Departamentos:</b> <span>{{ getSelectedDepartments }}</span>
                  </div>
                  <div class="filter-item one-line">
                    <b>Tags:</b> <span>{{ getSelectedTags }}</span>
                  </div>
                  <div v-if="pre_filter?.situation?.id === 'waiting_response'" class="filter-item one-line">
                    <v-chip x-small label class="error lighten-1">
                      Apenas não respondidas
                    </v-chip>
                  </div>
                  <div class="filter-item mb-1" style="display: flex; width: 100%;">
                    <v-spacer />
                    <ib-btn v-if="can_clear" small dark outlined color="error" class="mr-4" @click="clearFilters">
                      <v-icon left>mdi-filter-remove-outline</v-icon>
                      Limpar filtros
                    </ib-btn>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <div class="conversation-list d-flex" style="width: 100%;">
          <v-list three-line style="width: 100%; padding: 0px;">
            <v-list-item-group color="primary" style="width: 100%;">
              <v-list-item v-if="!loading && objects.length == 0" key="9999999" class="px-5" style="width: 100%;">
                <v-list-item-content>
                  <v-list-item-title style="font-weight: 500;" class="mb-2">
                    Sem conversas...
                  </v-list-item-title>
                  Tente utilizar outro filtro ou aguarde por novas mensagens
                </v-list-item-content>
              </v-list-item>

              <template v-for="item in objects">
                <v-list-item :key="item?._id" class="cursor-pointer px-5" @click="getMessages(item)">
                  <v-list-item-avatar size="60">
                    <ib-user-avatar :user="item.contact" size="60" color="gray75" rounded />
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title style="font-weight: 500;">
                      <v-icon v-if="item.waiting_response" size="18" color="error lighten-1" class="response-badge">
                        mdi-message-text-clock-outline
                      </v-icon>
                      {{ item.contact?.override_name || item.contact?.name || 'Sem nome' }}

                      <v-chip v-if="item.is_manual && item.is_new" small color="primary" class="mr-1">
                        Nova
                      </v-chip>
                    </v-list-item-title>

                    <message-preview class="my-2" :message="item.last_message?.message" :status="item.last_message?.status" :origin="item.last_message?.origin" :type="item.last_message?.type" />

                    <v-list-item-title>
                      <ib-chip v-for="(tag, index_tag) in item.contact?.tags" v-show="tags_dictionary[tag]" :key="`tag_${index_tag}`" small class="mr-1" :color="tags_dictionary[tag]?.color">
                        {{ tags_dictionary[tag]?.name }}
                      </ib-chip>

                      <div class="d-flex mt-2">
                        <ib-chip v-if="item.department" :color="$vuetify.theme.themes.light.gray400" small>
                          <v-icon small left>mdi-briefcase-outline</v-icon>
                          {{ item.department.name }}
                        </ib-chip>

                        <ib-chip v-if="!item.is_manual" small :color="$vuetify.theme.themes.light.primary" class="ml-2">
                          <v-icon small left>
                            {{ item.chatbot_type == 'flow_chatbot' ? 'mdi-sitemap-outline' : 'mdi-robot-outline' }}
                          </v-icon>Chatbot
                        </ib-chip>

                        <ib-chip v-if="item.is_manual" small :color="$vuetify.theme.themes.light.warning" class="ml-2">
                          <v-icon small left>
                            mdi-face-agent
                          </v-icon>Atendimento humano
                        </ib-chip>
                      </div>
                    </v-list-item-title>

                  </v-list-item-content>
                </v-list-item>
              </template>

              <div v-if="button_load_more" class="d-flex align-center justify-center mt-2">
                <ib-btn class="mb-4" small color="primary" @click="options.limit += 15; getConversations(options, false)">
                  Carregar mais
                </ib-btn>
              </div>
            </v-list-item-group>
          </v-list>
        </div>
      </v-col>

      <dialog-or-column v-model="showConversation" class="d-flex flex-column messages-background">
        <div class="messages-app-bar nd-border px-4 flex-grow-0" v-show="selectedConversation?._id" height="120">
          <v-btn v-if="isMobile" icon large class="mr-1" @click="showConversation = false">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>

          <ib-user-avatar class="my-2 cursor-pointer" v-if="selectedConversation?.contact" :user="selectedConversation?.contact" size="60" color="gray75" @click="openContact(selectedConversation?.contact?._id)" />

          <div class="contact-info cursor-pointer">
            <span class="contact-name">
              <v-icon v-if="selectedConversation.waiting_response" size="20" color="error lighten-1" class="response-badge mr-1">mdi-message-text-clock-outline</v-icon>
              <span @click="openContact(selectedConversation?.contact?._id)">
                {{ selectedConversation?.contact?.override_name || selectedConversation?.contact?.name || "Contato" }}
                <small v-if="selectedConversation?.is_manual">(Atendimento humano)</small></span>
              <ib-chip v-if="selectedConversation.department" class="ml-2 cursor-pointer" :color="$vuetify.theme.themes.light.gray400" small @click="departmentDialog = true"><v-icon small left>mdi-briefcase-outline</v-icon>{{
                selectedConversation.department.name }}</ib-chip>
            </span>
            <div v-if="selectedConversation.tags?.length > 0">
              <ib-chip v-for="(tag, index_tag) in selectedConversation.tags" v-show="tags_dictionary[tag]" :key="`tag_${index_tag}`" x-small class="mr-1" :color="tags_dictionary[tag]?.color">
                {{ tags_dictionary[tag]?.name }}
              </ib-chip>
            </div>
            <span class="contact-phone" @click="openContact(selectedConversation?.contact?._id)">
              {{ typing ? 'Digitando...' : $utils.formatPhone(selectedConversation?.contact?.phone) }}
            </span>
          </div>

          <v-spacer />

          <v-menu v-if="selectedConversation?._id" offset-y>
            <template #activator="{ on }">
              <v-btn icon color="gray500" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item class="cursor-pointer" @click="openContact(selectedConversation?.contact?._id)">
                <v-list-item-title>
                  <v-icon size="25" class="mr-1" color="primary">
                    mdi-account-details-outline
                  </v-icon>
                  Dados do contato
                </v-list-item-title>
              </v-list-item>

              <v-list-item class="cursor-pointer" @click="selectedConversation.waiting_response = false; changeConversationData()">
                <v-list-item-title>
                  <v-icon size="25" class="mr-1" color="primary">
                    mdi-check-circle-outline
                  </v-icon>
                  Marcar como respondida
                </v-list-item-title>
              </v-list-item>

              <v-list-item class="cursor-pointer" @click="dialogScheduledMessage = true">
                <v-list-item-title>
                  <v-icon size="25" class="mr-1" color="primary">
                    mdi-calendar-clock-outline
                  </v-icon>
                  Agendar mensagem
                </v-list-item-title>
              </v-list-item>

              <v-list-item class="cursor-pointer" @click="tagsDialog = true">
                <v-list-item-title>
                  <v-icon size="25" class="mr-1" color="primary">
                    mdi-tag-multiple-outline
                  </v-icon>
                  Atribuir tags
                </v-list-item-title>
              </v-list-item>

              <v-list-item class="cursor-pointer" @click="flowsDialog = true">
                <v-list-item-title>
                  <v-icon size="25" class="mr-1" color="primary">
                    mdi-sitemap-outline
                  </v-icon>
                  Transferir para fluxo
                </v-list-item-title>
              </v-list-item>

              <v-list-item v-if="!selectedConversation.finished" class="cursor-pointer" @click="departmentDialog = true">
                <v-list-item-title>
                  <v-icon size="25" class="mr-1" color="primary">
                    mdi-briefcase-arrow-left-right-outline
                  </v-icon>
                  Transferir departamento
                </v-list-item-title>
              </v-list-item>

              <v-list-item v-if="selectedConversation.finished" class="cursor-pointer" @click.native="resumeConversation(selectedConversation)">
                <v-list-item-title>
                  Retomar atendimento
                </v-list-item-title>
              </v-list-item>

              <v-list-item v-if="!selectedConversation.is_manual && !selectedConversation.finished" class="cursor-pointer" @click="setManual(selectedConversation)">
                <v-list-item-title>
                  <v-icon size="25" class="mr-1" color="primary">
                    mdi-human-greeting
                  </v-icon>
                  Atender como humano
                </v-list-item-title>
              </v-list-item>

              <v-list-item v-if="!selectedConversation.finished" class="cursor-pointer" @click="finishConversation(selectedConversation)">
                <v-list-item-title>
                  <v-icon size="25" class="mr-1" color="primary">
                    mdi-check-outline
                  </v-icon>
                  Finalizar atendimento
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <div class="conversation-container flex-grow-1">
          <div ref="dialogRef" class="messages-container flex-grow-1 pa-4">
            <div class="d-flex justify-center">
              <ib-btn v-if="rendered_messages.length < messages.length" class="mb-4" small color="primary" @click="loadMoreMessages">
                Carregar mais
              </ib-btn>
            </div>

            <div v-for="(item, message_index) in rendered_messages" :id="`message_${message_index}`" :key="`message_${message_index}`" :class="`fade chat-item ${item.origin == 'customer' ? 'customer' : 'chatbot'}-message ${item.deleted ? 'deleted-message' : ''} ${item.type === 'summary' ? 'summary-message' : ''}`" @click="selectedChatItem = item">
              <div v-if="item.deleted" style="font-size: 12px" class="mb-2">
                <i>(Essa mensagem foi apagada)</i>
              </div>

              <div v-if="item.user">
                <b>{{ users[item.user] || 'Colaborador' }}</b>
              </div>

              <div v-if="item.reply_message?.type == 'text'" class="reply-container">
                <v-icon small>
                  mdi-reply-outline
                </v-icon>
                {{ item.reply_message.message }}
              </div>

              <audio v-if="item.type == 'audio' && item.media_url" controls class="mb-2">
                <source :src="item.media_url" type="audio/mpeg">
                Seu navegador não suporte player de audio.
              </audio>
              <span v-if="item.type == 'audio' && item.origin == 'customer'">[TRANSCRIÇÃO]: </span>

              <video v-if="item.type == 'video'" class="video-container" controls="controls">
                <source :src="item.media_url" type="video/mp4">
                <!--Suportado em IE9, Chrome 6 e Safari 5 -->
                O seu navegador não suporta a tag vídeo
              </video>

              <div v-if="item.type == 'image' || (item.type == 'text' && item.media_url)">
                <v-img class="cursor-pointer" :src="item.media_url" contain height="auto" max-width="80vw" @click="showImage(item.media_url)" />
              </div>

              <div v-if="item.type == 'document'" class="cursor-pointer pa-2" @click="openFile(item.media_url)">
                <v-icon size="40">
                  mdi-file-document-outline
                </v-icon>
                <div style="font-weight: 400;" class="mt-2">
                  {{ item.file_name || 'Documento' }}
                </div>
              </div>

              <div v-if="item.canonical_url">
                <b>{{ item.canonical_url }}</b>
              </div>

              <div v-if="item.type === 'summary'">
                <b>RESUMO DA CONVERSA</b> <small>(o contato não vê o resumo)</small>
              </div>

              <div>
                {{ item.message }}
              </div>

              <div class="d-flex align-center">
                <v-btn v-if="item.origin != 'customer' && selectedChatItem.platform_message_id == item.platform_message_id && !item.deleted && item.type != 'summary'" small icon @click="deleteMessageDialog = true">
                  <v-icon size="18">
                    mdi-trash-can
                  </v-icon>
                </v-btn>
                <v-spacer />
                <div v-if="item.created" class="message-time">
                  {{ $date.smallFormat(item.created) }}
                  <v-icon v-if="item.origin != 'customer'" size="13" :color="item.status >= 4 || last_read_message_index >= message_index ? 'blue' : 'grey'">
                    mdi-check-all
                  </v-icon>
                </div>
              </div>
            </div>

            <div v-if="rendered_messages.length" class="d-flex justify-center mt-1">
              <v-chip dark small color="purple lighten-2" class="cursor-pointer mr-2" @click="summarizeMessages">
                <v-icon size="14" class="mr-2">
                  mdi-creation-outline
                </v-icon> {{ summary_ai_loading ? 'Resumindo num passe de mágica...' : 'Resumir com IA' }}
              </v-chip>
              <v-chip v-if="selectedConversation.is_manual" dark small color="purple lighten-2" class="cursor-pointer" @click="createAIFollowUp">
                <v-icon size="14" class="mr-2">
                  mdi-magic-staff
                </v-icon> {{ follow_up_ai_loading ? 'Criando follow-up personalizado...' : 'Sugerir follow-up com IA' }}
              </v-chip>
            </div>
          </div>

          <div v-if="selectedConversation.is_manual && !selectedConversation.finished" class="flex-grow-0 command-bar ">
            <div class="d-flex my-4 mr-4 fade flex-grow-1 align-center">
              <v-btn v-show="text == ''" icon class="mr-1" @click="openMedias">
                <v-icon>mdi-paperclip</v-icon>
              </v-btn>

              <v-btn v-show="text == ''" icon class="mr-1" @click="openRecording">
                <v-icon>mdi-microphone</v-icon>
              </v-btn>

              <v-btn icon class="mr-1" @click.stop="toogleDialogEmoji">
                <v-icon>mdi-emoticon-happy-outline</v-icon>
              </v-btn>

              <div id="exampleInputEmoji" @click.stop="() => { }">
                <VEmojiPicker v-show="emojiDialog" :style="{ width: '400px', height: '200' }" :show-search="false" lang="pt-BR" @select="onSelectEmoji" @click.stop="true" />
              </div>


              <v-layout column>
                <v-menu ref="menu" v-model="menu" attach=".ref1" top>
                  <v-list dense style="min-height: 200px; max-height: 200px;width: 90vw;max-width: 800px; overflow-y: auto;">
                    <v-list-item v-for="(item, index) in filteredItems" :key="index" background-color="white" @click="text = item.message">
                      <v-list-item-title>{{ item.shortcut }}: {{ item.message.length < 60 ? item.message : item.message.substring(0, 59) + '...' }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>

                <v-textarea v-model="text" class="ref1 nd-text-field" outlined background-color="gray25" rows="2" no-resize hide-details @keydown.enter="handleKeyDown" @input="handleInput" />
              </v-layout>

              <v-btn class="ml-2" fab dark small color="success" @click.native="sendMessage">
                <v-icon dark>
                  mdi-send
                </v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </dialog-or-column>
    </v-row>

    <v-bottom-sheet v-model="filter_sheet">
      <v-list nav dense>
        <v-list-item class="mb-0">
          <v-list-item-title>Situação do atendimento</v-list-item-title>
        </v-list-item>
        <v-list-item class="mb-0">
          <v-btn-toggle v-model="pre_filter.situation" mandatory dense color="primary">
            <v-btn v-for="(item, index) in conversation_situations" :key="index" small :value="item">
              <v-icon size="18" class="mr-2">
                {{ item.icon }}
              </v-icon>
              <span>{{ item.name }}</span>
            </v-btn>
          </v-btn-toggle>
        </v-list-item>

        <v-list-item class="mb-0">
          <v-list-item-title>Tipo de atendimento</v-list-item-title>
        </v-list-item>
        <v-list-item class="mb-0">
          <v-btn-toggle v-model="pre_filter.types" multiple mandatory dense color="primary">
            <v-btn v-for="(item, index) in types" :key="index" small :value="item">
              <v-icon size="18" class="mr-2">
                {{ item.icon }}
              </v-icon>
              <span>{{ item.name }}</span>
            </v-btn>
          </v-btn-toggle>
        </v-list-item>

        <v-list-item class="mb-0">
          <v-list-item-title>Departamento</v-list-item-title>
        </v-list-item>

        <v-list-item class="mb-0">
          <ib-autocomplete outlined v-model="pre_filter.departments" :items="user_role_departments" :label="pre_filter.departments?.length ? 'Departamentos selecionados' : 'Todos os departamentos'" prepend-inner-icon="mdi-briefcase-outline" item-text="name" item-value="_id" small-chips return-object clearable multiple hide-details></ib-autocomplete>
        </v-list-item>

        <v-list-item class="mb-0">
          <v-list-item-title>Tags</v-list-item-title>
        </v-list-item>

        <v-list-item class="mb-0">
          <ib-autocomplete outlined v-model="pre_filter.tags" :items="tags" :label="pre_filter.tags?.length ? 'Tags selecionadas' : 'Todas as tags'" prepend-inner-icon="mdi-tag-multiple-outline" item-text="name" item-value="_id" small-chips return-object clearable multiple hide-details></ib-autocomplete>
        </v-list-item>

        <v-list-item class="mb-0">
          <v-list-item-title>Busca individual</v-list-item-title>
        </v-list-item>


        <v-list-item class="mb-2">
          <ib-text-field prepend-inner-icon="mdi-account-outline" v-model="pre_filter.text" outlined label="Nome ou telefone" hide-details="auto" />
        </v-list-item>

        <v-list-item>
          <div class="filter-item my-3" style="display: flex; width: 100%;">
            <v-spacer />
            <ib-btn dark outlined color="error" class="mr-4" @click="clearFilters">
              Limpar filtros
            </ib-btn>
            <ib-btn dark color="primary" @click="applyFilters">
              Aplicar filtros
            </ib-btn>
          </div>
        </v-list-item>
      </v-list>
    </v-bottom-sheet>

    <ib-dialog v-model="deleteMessageDialog" overlay-opacity="0.5" overlay-color="black" max-width="350">
      <v-card>
        <ib-dialog-header title="Apagar mensagem" :close="() => { deleteMessageDialog = false }"></ib-dialog-header>
        <v-card-text> Tem certeza que deseja apagar essa mensagem? Essa ação é irreversível.</v-card-text>
        <v-card-actions>
          <ib-btn outlined color="gray200" @click="deleteMessageDialog = false;">
            <div class="gray1000--text">
              Cancelar
            </div>
          </ib-btn>

          <v-spacer />

          <ib-btn color="error" @click="deleteMessageDialog = false; deleteMessage()">
            Apagar
          </ib-btn>
        </v-card-actions>
      </v-card>
    </ib-dialog>


    <ib-dialog v-model="newConversationDialog" overlay-opacity="0.5" overlay-color="black" max-width="550">
      <v-card>
        <ib-dialog-header title="Nova conversa" subtitle="Inicie uma nova conversa por aqui" :close="() => { newConversationDialog = false }"></ib-dialog-header>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <ib-select v-model="new_conversation_chip" outlined :items="allowed_chips" item-text="name" no-data-text="Não há números conectados" item-value="_id" label="Canal de comunicação" hide-details="auto" />
            </v-col>

            <v-col cols="12">
              <ib-select v-model="new_conversation_department" outlined :items="user_role_departments" item-text="name" no-data-text="Não há departamentos" item-value="_id" label="Departamento" hide-details="auto" />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text>
          <v-radio-group v-model="new_conversation_contact_type" class="my-2" row hide-details="auto">
            <v-radio label="Contato existente" value="existing" />
            <v-radio label="Novo contato" value="new" />
          </v-radio-group>
        </v-card-text>

        <v-card-text v-if="new_conversation_contact_type == 'existing'">
          <ib-autocomplete v-model="new_contact" return-object outlined :items="contacts" :loading="loading" chips clearable hide-details="auto" :item-text="getItemText" item-value="_id" label="Selecione um contato" @update:search-input="searchContacts">
            <template #no-data>
              <v-list-item>
                <v-list-item-title>
                  Busque um contato pelo
                  <strong>nome ou telefone</strong>
                </v-list-item-title>
              </v-list-item>
            </template>
            <template #selection="{ attr, on, item, selected }">
              <v-chip v-bind="attr" :input-value="selected" color="primary" class="white--text" v-on="on">
                <span v-text="item.override_name || item.name" />
              </v-chip>
            </template>
            <template #item="{ item }">
              <v-list-item-avatar class="text-h5 font-weight-light white--text">
                <v-img v-if="item.contact?.profile_picture" :src="item.contact?.profile_picture" />
                <div v-else class="w-full h-full primary d-flex justify-center">
                  <v-icon color="white" style="font-size:55px">
                    mdi-account
                  </v-icon>
                </div>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-text="item.override_name || item.name" />
                <v-list-item-subtitle v-text="item.phone" />
              </v-list-item-content>
            </template>
          </ib-autocomplete>
        </v-card-text>

        <v-card-text v-if="new_conversation_contact_type == 'new'">
          <v-row>
            <v-col cols="3">
              <ib-text-field v-model="country_code" outlined label="Cód País" hide-details="auto" />
            </v-col>
            <v-col cols="9">
              <ib-text-field v-model="new_conversation_phone" outlined label="Telefone com DDD" hide-details="auto" @change="new_conversation_jid = false" />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <ib-btn outlined color="gray200" @click="newConversationDialog = false;">
            <div class="gray1000--text">
              Cancelar
            </div>
          </ib-btn>

          <v-spacer />

          <ib-btn v-if="new_conversation_contact_type == 'new' && !new_conversation_jid" color="primary" :disabled="!new_conversation_chip" @click="checkPhone">
            Iniciar conversa
          </ib-btn>

          <ib-btn v-if="new_conversation_jid || (new_conversation_contact_type == 'existing' && new_contact)" color="primary" @click="startNewConversation">
            Iniciar conversa
          </ib-btn>
        </v-card-actions>
      </v-card>
    </ib-dialog>

    <ib-dialog v-model="tagsDialog" overlay-opacity="0.5" overlay-color="black" persistent max-width="600">
      <v-card>
        <ib-dialog-header title="Atribuir tags" :subtitle="`Organize o atendimento de  ${selectedConversation?.contact?.override_name || selectedConversation?.contact?.name}`" :close="() => { tagsDialog = false }"></ib-dialog-header>

        <v-card-text v-if="selectedConversation?.contact">
          <ib-autocomplete v-model="selectedConversation.contact.tags" chips small-chips multiple outlined :items="tags" item-text="name" item-value="_id" label="Tags ou rótulos" hide-details="auto">
            <template #selection="{ item }">
              <ib-chip small :color="item.color">
                {{ item.name }}
              </ib-chip>
            </template>
          </ib-autocomplete>
        </v-card-text>

        <v-card-actions>
          <ib-btn outlined color="gray200" @click="tagsDialog = false;">
            <div class="gray1000--text">
              Cancelar
            </div>
          </ib-btn>

          <v-spacer />

          <ib-btn color="primary" @click="tagsDialog = false; changeContactTags()">
            Salvar
          </ib-btn>
        </v-card-actions>
      </v-card>
    </ib-dialog>

    <ib-dialog v-model="flowsDialog" overlay-opacity="0.5" overlay-color="black" persistent max-width="600">
      <v-card>
        <ib-dialog-header title="Transferir para fluxo" :subtitle="`Organize o atendimento de  ${selectedConversation?.contact?.override_name || selectedConversation?.contact?.name}`" :close="() => { flowsDialog = false }"></ib-dialog-header>

        <v-card-text v-if="selectedConversation?.contact">
          <ib-autocomplete v-model="selectedConversation.flow" chips small-chips outlined :items="flows" item-text="name" item-value="_id" label="Fluxo" hide-details="auto">
            <template #selection="{ item }">
              <ib-chip small color="primary">
                {{ item.name }}
              </ib-chip>
            </template>
          </ib-autocomplete>
        </v-card-text>

        <v-card-actions>
          <ib-btn outlined color="gray200" @click="flowsDialog = false;">
            <div class="gray1000--text">
              Cancelar
            </div>
          </ib-btn>

          <v-spacer />

          <ib-btn color="primary" :disabled="!selectedConversation.flow" @click="flowsDialog = false; transferToFlow()">
            Transferir
          </ib-btn>
        </v-card-actions>
      </v-card>
    </ib-dialog>

    <ib-dialog v-model="departmentDialog" overlay-opacity="0.5" overlay-color="black" persistent max-width="600">
      <v-card>
        <ib-dialog-header title="Transferir departamento" :subtitle="`Transferir atendimento de  ${selectedConversation?.contact?.override_name || selectedConversation?.contact?.name}`" :close="() => { departmentDialog = false }"></ib-dialog-header>

        <v-card-text>
          <v-autocomplete v-model="selectedConversation.department" chips small-chips outlined :items="departments" item-text="name" item-value="_id" label="Departamento" hide-details="auto">
            <template #selection="{ item }">
              <ib-chip small color="primary">
                {{ item.name }}
              </ib-chip>
            </template>
          </v-autocomplete>
        </v-card-text>

        <v-card-actions>
          <ib-btn outlined color="gray200" @click="departmentDialog = false;">
            <div class="gray1000--text">
              Cancelar
            </div>
          </ib-btn>

          <v-spacer />

          <ib-btn color="primary" @click="departmentDialog = false; changeConversationData()">
            Transferir
          </ib-btn>
        </v-card-actions>
      </v-card>
    </ib-dialog>

    <v-overlay v-model="imageViewerDialog" z-index="99999" opacity="0.9">
      <v-img :src="media_url" contain style="max-height: 85vh;max-width:85vw" />
      <div style="text-align: center;">
        <ib-btn outlined class="mt-2 mr-4" large color="error lighten-1" @click="imageViewerDialog = false">
          <v-icon class="mr-2">
            mdi-close
          </v-icon>
          Fechar
        </ib-btn>
        <ib-btn class="mt-2" large color="primary" @click="$saveImage(media_url)">
          <v-icon class="mr-2">
            mdi-content-save-outline
          </v-icon>
          Salvar
        </ib-btn>
      </div>
    </v-overlay>

    <dialog-medias v-model="dialogMedia" :medias="medias" :update-medias="updateMedias" :send-media="sendMedia" />
    <dialog-scheduled-message v-model="dialogScheduledMessage" :phone="selectedConversation?.contact?.phone" :chips="chips" />
    <dialog-recording v-model="dialogRecording" :send-media="sendMedia" />
    <contact-drawer v-model="contactDrawer" :contact_id="selectedContact" :tags_dictionary="tags_dictionary" />

    <dialog-finish-conversations v-if="['owner', 'manager'].includes(currentUser?.user_role)" v-model="dialogFinishConversations" />
  </div>
</template>

<script>
let socket;
let socket_connection_count = 0;

import Api from "@/lib/Api";
import MessagePreview from "@/components/MessagePreview";
import DialogOrColumn from "@/components/DialogOrColumn";
import DialogMedias from "@/components/DialogMedias";
import DialogFinishConversations from "@/components/DialogFinishConversations.vue";
import DialogRecording from "@/components/DialogRecording";
import DialogScheduledMessage from "@/components/DialogScheduledMessage";
import ContactDrawer from "@/components/ContactDrawer";
import { emitToastr } from "@/lib/Utils";
import { VEmojiPicker } from 'v-emoji-picker';
let timeout = null;

export default {
  name: "AtendimentoView",

  components: {
    MessagePreview,
    DialogOrColumn,
    DialogMedias,
    DialogFinishConversations,
    DialogRecording,
    DialogScheduledMessage,
    ContactDrawer,
    VEmojiPicker
  },

  data() {
    return {
      selectedChatItem: {},
      filter_sheet: false,
      objects: [],
      tagsDialog: false,
      flowsDialog: false,
      departmentDialog: false,
      dialogMedia: false,
      dialogRecording: false,
      dialogScheduledMessage: false,
      newConversationDialog: false,
      deleteMessageDialog: false,
      dialogFinishConversations: false,
      contactDrawer: false,
      selectedContact: null,
      emojiDialog: false,
      imageViewerDialog: null,

      menu: false,
      filteredItems: [],

      media_url: '',
      selectedConversation: {},
      showConversation: false,
      pre_filter: {
        text: '', changed: false, tags: [], departments: [],
        types: [
          { name: 'Chatbot', id: 'chatbot', icon: 'mdi-robot-outline' },
          { name: 'Humano', id: 'human', icon: 'mdi-face-agent' }
        ],
        situation: 'all'
      },
      can_clear: false,
      options: { filter: { text: '', finished: undefined }, limit: 15 },
      messages: [],
      rendered_messages: [],
      last_read_message_index: -1,
      quick_messages: [],
      autoselectMenu: false,
      button_load_more: true,
      text: "",
      loading: true,
      typing: false,
      types: [
        { name: 'Chatbot', id: 'chatbot', icon: 'mdi-robot-outline' },
        { name: 'Humano', id: 'human', icon: 'mdi-face-agent' }
      ],
      conversation_situations: [
        { name: 'Todas', id: 'all', icon: 'mdi-forum-outline' },
        { name: 'Não respondidas', id: 'waiting_response', icon: 'mdi-message-text-clock-outline' }
      ],
      chips: [],
      allowed_chips: [],
      medias: [],
      users: {},
      departments: [],
      user_role_departments: [],
      flows: [],
      contacts: [],
      tags: [],
      tags_dictionary: {},
      new_conversation_phone: "",
      new_conversation_contact_type: "existing",
      country_code: "55",
      new_conversation_jid: false,
      new_conversation_chip: false,
      new_conversation_department: false,
      new_contact: null,
      summary_ai_loading: false,
      follow_up_ai_loading: false
    };
  },

  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },

    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },

    showMessageDialog() {
      if (this.conversation?._id) return true;
      return false;
    },
    getSelectedTypes() {
      if (this.pre_filter?.types && this.pre_filter.types.length > 0) {
        let selections = '';
        this.pre_filter.types.forEach(i => selections = selections + `, ${i.name}`)
        return selections.substring(2);
      }
      else {
        return 'Todos os tipos'
      }
    },

    getSelectedDepartments() {
      if (this.pre_filter?.departments && this.pre_filter.departments.length > 0) {
        let selections = '';
        this.pre_filter.departments.forEach(i => selections = selections + `, ${i.name}`)
        return selections.substring(2);
      }
      else {
        return 'Todos os departamentos'
      }
    },

    getSelectedTags() {
      if (this.pre_filter?.tags && this.pre_filter.tags.length > 0) {
        let selections = '';
        this.pre_filter.tags.forEach(i => selections = selections + `, ${i.name}`)
        return selections.substring(2);
      }
      else {
        return 'Todos os contatos'
      }
    }
  },
  watch: {
    text(value) {
      if (value.endsWith('/')) {
        this.filteredItems = this.quick_messages;
        this.menu = true;
      } else if (!value.includes('/')) {
        this.menu = false;
      }
    }
  },

  async mounted() {
    this.loading = true;

    if (!socket) this.startSocket();
    const tags_call = Api.getTagsByUser();
    const departments_call = Api.getDepartmentsByUser();
    const quick_messages_call = Api.getQuickMessagesByUser();
    const chips_call = Api.getChipNumbersByUser();
    const medias_call = Api.getMediasByUser();
    const users_call = Api.getTeamUsers();
    const flows_call = Api.getFlowsByUser();

    const promisess = [await tags_call, await departments_call, await quick_messages_call, await chips_call, await medias_call, await users_call, await flows_call];

    const tags = promisess[0];
    const departments = promisess[1];
    const quick_messages = promisess[2];
    const chips = promisess[3];
    const medias = promisess[4];
    const users = promisess[5];
    const flows = promisess[6];

    if (!tags.error && tags.message) this.tags = tags.message;
    this.tags.forEach(i => this.tags_dictionary[i._id] = { name: i.name, color: i.color });


    if (!departments.error && departments.message) {
      this.departments = departments.message;

      if (this.currentUser.user_role == 'user') {
        this.user_role_departments = this.departments.filter(d => (this.currentUser.departments || []).includes(d._id.toString()));
      }
      else {
        this.user_role_departments = this.departments;
      }
    }

    if (!quick_messages.error && quick_messages.message) {
      this.quick_messages = [];
      quick_messages.message.forEach(m => this.quick_messages.push({ shortcut: `/${m.shortcut}`, message: m.message }));
    }

    if (!chips.error && chips.message) {
      this.chips = chips.message.filter(i => i.status == 'open');

      if (this.currentUser.user_role === 'user') {
        this.allowed_chips = this.chips.filter(c => (this.currentUser.departments || []).includes(c.department));
      }
      else {
        this.allowed_chips = this.chips;
      }

      if (this.allowed_chips.length) this.new_conversation_chip = this.allowed_chips[0];
    }

    if (!medias.error && medias.message) {
      this.medias = medias.message;
    }

    if (!users.error && users.message) {
      this.users = {};
      users.message.forEach(u => { this.users[u._id.toString()] = u.name; })
    }

    if (!flows.error && flows.message) {
      this.flows = flows.message;
    }

    this.buildFilter();
    //NOTE: OK
    await this.getConversations(this.options);

    if (this.isMobile) {
      try {
        document.removeEventListener("visibilitychange", () => { });

        document.addEventListener("visibilitychange", async () => {
          if (!document.hidden) {
            //NOTE: OK
            this.getConversations(this.options);
          }
        });
      }
      catch (err) {
        console.log(err.message)
      }
    }
  },

  async beforeDestroy() {
    socket.disconnect();
    socket = null;
  },

  methods: {
    toogleDialogEmoji() {
      this.emojiDialog = !this.emojiDialog;
    },
    onSelectEmoji(emoji) {
      this.text += emoji.data;
      // Optional
      // this.toogleDialogEmoji();
    },
    handleKeyDown(event) {
      event.preventDefault();

      if (event.key === 'Enter') {
        if (event.shiftKey) {
          this.text += '\n';
        } else {
          this.sendMessage();
        }
      }
    },

    handleInput() {
      if (this.text && this.text[0] == '/') {
        this.filteredItems = this.quick_messages.filter((item) =>
          item.shortcut.toLowerCase().includes(this.text.split('/').pop().toLowerCase())
        );
        this.menu = true;
      } else {
        this.menu = false;
      }
    },

    buildFilter() {
      this.options.filter.types = this.pre_filter.types;
      this.options.filter.tags = this.pre_filter.tags;
      this.options.filter.text = this.pre_filter.text;
      this.options.filter.situation = this.pre_filter.situation?.id;
      this.options.limit = 15;

      //TRATANDO DEPARTAMENTOS
      if (this.currentUser.user_role == 'user') {
        if (!this.currentUser.departments) this.currentUser.departments = [];

        if (this.pre_filter.departments.length == 0) {
          this.pre_filter.departments = this.user_role_departments;
        }
      }

      this.options.filter.departments = this.pre_filter.departments;
    },

    sortConversations() {
      this.objects = this.objects.sort((a, b) => {
        const dateA = a.last_message && a.last_message.created ? new Date(a.last_message.created).getTime() : 0;
        const dateB = b.last_message && b.last_message.created ? new Date(b.last_message.created).getTime() : 0;
        return dateB - dateA;
      });
    },

    async sendMessage(media = {}) {
      if (!media?._id && !media?.url && this.text == '') return;

      this.$nextTick(async () => {
        const m_id = this.$newID('.OP');
        this.selectedConversation.waiting_response = false;

        this.pushMessage({ created: new Date(), event: 'new_message', conversation_id: this.selectedConversation._id, message: this.text, message_id: m_id, platform_message_id: m_id, origin: 'user', type: media.type || 'text', media_url: media?.url, user: this.currentUser._id });

        let send_message_call = Api.sendConversationMessage(this.selectedConversation._id, this.text, m_id, media);
        this.selectedConversation.waiting_response = false;
        this.selectedConversation.is_new = false;
        this.text = "";

        send_message_call = await send_message_call;

        //APAGANDO MENSAGEM COM ERRO
        if (send_message_call.error) {
          const error_message_index = this.messages.findIndex(m => m.message_id === m_id);
          this.messages.splice(error_message_index, 1);
        }
      });
    },

    startSocket() {
      if (socket_connection_count >= 3) {
        emitToastr("error", "Erro na conexão com o socket. Atualize a página para tentar novamente.");
        return;
      }

      if (!this.$store.state.SOCKET_IO_URL) {
        socket_connection_count++
        setTimeout(() => { this.startSocket(); }, 500 + socket_connection_count * 2000);
        emitToastr("info", "Aguardando endereço do socket...");
        return;
      }

      socket_connection_count = 0;
      socket = this.$io(this.$store.state.SOCKET_IO_URL, { path: '/sock/' });

      socket.on("connect", async () => {
        socket.emit("join-room", `account_conversations_${this.currentUser?.account?._id}`);
      });

      socket.on("receive-message", data => {
        if (data.event === "new_message") {
          this.pushMessage(data);
        }

        if (data.event === "new_conversation") {
          setTimeout(() => {
            this.getConversations({ ...this.options, conversation_id: data.conversation._id }, true);
          }, 2000);
        }

        if (data.event === "conversation_updated") {
          setTimeout(() => {
            this.getConversations({ ...this.options, conversation_id: data.conversation._id }, true);
          }, 1000);
        }

        if (data.event === "conversation_finished") {
          if (this.selectedConversation?._id?.toString() == data.conversation_id) {
            if (data.user_id && this.currentUser._id != data.user_id) emitToastr("info", "Essa conversa foi finalizada por outro usuário");
            this.selectedConversation = {};
            this.messages = [];
            this.rendered_messages = [];
          }

          const c_index = this.objects.findIndex(i => i._id.toString() == data.conversation_id);
          if (c_index != -1) {
            this.objects.splice(c_index, 1);
            this.$forceUpdate();
          }
        }

        if (data.event === "message_read") {
          if (this.showConversation && this.selectedConversation?._id?.toString() == data.conversation_id) this.setMessagesRead(data.platform_message_id);
        }

        if (data.event === "set_manual") {
          this.getConversations({ ...this.options, conversation_id: data.conversation_id }, true);

          if (this.selectedConversation?._id?.toString() == data.conversation_id.toString()) {
            this.selectedConversation.is_manual = true;
          }
        }
      });
    },

    findConversationIndex(conversation_id) {
      return this.objects.findIndex(c => c._id.toString() === conversation_id);
    },

    async getConversations(options, notify = false) {
      this.loading = true;
      let resp = await Api.getConversationsWithLastMessage(options);
      this.loading = false;

      const last_conversation_list_length = this.objects.length;

      if (!resp.error) {
        //ÚNICA CONVERSA
        if (options.conversation_id) {
          const conversation_index = this.findConversationIndex(options.conversation_id);

          if (conversation_index != -1) {
            //EXISTE NA LISTA
            if (resp.message) {
              //O USUÁRIO PODE VER A CONVERSA - SUBSTITUIR
              this.objects[conversation_index] = resp.message;
            }
            else {
              //NÃO PODE VER A CONVERSA - APAGAR
              this.objects.splice(conversation_index, 1);
            }
          }
          else {
            //NÃO EXISTE NA LISTA
            if (resp.message) {
              this.objects.push(resp.message);
            }
          }
        }
        else {
          //LISTA DE CONVERSAS
          this.objects = resp.message;
          this.button_load_more = resp.load_more;
        }

        if (this.pre_filter.tags.length > 0) this.objects = this.objects.filter(i => this.pre_filter.tags.some(j => i.contact?.tags.includes(j._id)));
        this.totalObjects = resp.message.length;

        if (this.selectedConversation?._id) {
          this.selectedConversation = this.objects.find(x => x._id == this.selectedConversation._id);
        }

        if (!this.selectedConversation) {
          this.selectedConversation = {};
          this.messages = [];
          this.rendered_messages = [];
        }

        this.sortConversations();

        //NOTIFICAR CASO TENHA UMA MENSAGEM NOVA
        if (notify && last_conversation_list_length < this.totalObjects) this.$notify("InteligênciaBot", "Nova conversa");
      }
    },

    async pushMessage(data) {
      const conversation_index = this.findConversationIndex(data.conversation_id);

      if (conversation_index != -1) {
        //CONVERSA EXISTE NA LISTA
        if (data.type && data.type != 'summary') this.objects[conversation_index].last_message = data;

        if (data.origin === 'customer') {
          this.objects[conversation_index].waiting_response = true;
        }
        else {
          this.objects[conversation_index].waiting_response = false;
          //if (this.options.filter.situation == "waiting_response") this.objects.splice(conversation_index, 1);
        }

        this.sortConversations();
        this.$forceUpdate()
      }
      else {
        //CONVERSA NÃO ESTÁ LISTADA
        setTimeout(() => {
          this.getConversations({ ...this.options, conversation_id: data.conversation_id }, true);
        }, 1000);
      }

      if (data?.conversation_id.toString() != this.selectedConversation?._id?.toString()) {
        if (this.selectedConversation?._id?.toString() != data.conversation_id.toString() && data.origin == 'customer' && !this.currentUser.config?.disable_notification_sound) this.$playNotificationSound();
        return;
      }

      let delay = 1;

      if (data.origin == 'customer') {
        this.typing = true;
        delay = 1000;
      }

      let message = this.messages.find(m => m.message_id == data.message_id);
      if (message && !message.platform_message_id && data.platform_message_id) message.platform_message_id = data.platform_message_id;

      setTimeout(() => {
        if (!data.message_id || !message) {
          this.messages.push(data);
          this.rendered_messages.push(data);
          this.last_read_message_index = this.messages.findLastIndex(m => m.status >= 4);
          this.typing = false;

          this.$nextTick(() => {
            this.scrollMessagesToBottom('smooth');
          });
        }
      }, delay);
    },

    scrollMessagesToBottom(behavior = 'smooth') {
      const dialogElement = this.$refs.dialogRef;

      if (dialogElement) {
        setTimeout(() => {
          dialogElement.scrollTo({ top: dialogElement.scrollHeight + 1000, behavior });
        }, 30);
      }
    },

    setMessagesRead(message_id) {
      const message_index = this.messages.findIndex(m => m.platform_message_id == message_id);

      if (this.messages[message_index]) {
        this.messages[message_index].status = 4;
        if (message_index > this.last_read_message_index) this.last_read_message_index = message_index;
        this.$forceUpdate();
      }
    },

    loadMoreMessages() {
      this.rendered_messages = this.messages.slice(-1 * (this.rendered_messages.length + 25));

      this.$nextTick(() => {
        const element = document.getElementById(`message_25`);
        if (element) element.scrollIntoView({ behavior: "instant", block: "start" });
      })
    },

    async getMessages(conversation) {
      if (this.selectedConversation && this.selectedConversation._id != conversation._id) {
        this.text = "";
        this.messages = [];
        this.rendered_messages = [];
      }

      if (this.selectedConversation && this.selectedConversation._id == conversation._id) {
        if (!this.showConversation) this.showConversation = true;
        return;
      }

      this.typing = false;
      this.selectedConversation = conversation;
      this.showConversation = true;
      const resp = await Api.getMessagesByConversation(conversation._id);

      if (!resp.error && resp.message) {
        this.messages = resp.message;
        this.rendered_messages = this.messages.slice(-25);
        this.last_read_message_index = this.messages.findLastIndex(m => m.status >= 4);

        setTimeout(() => {
          this.scrollMessagesToBottom('instant');
        }, 50);
      }
    },

    async finishConversation(conversation) {
      const resp = await Api.finishConversation(conversation._id);

      if (!resp.error && resp.message) {
        const conversation_index = this.findConversationIndex(conversation._id.toString());
        if (conversation_index != -1) this.objects.splice(conversation_index, 1);

        this.selectedConversation = {};
        this.showConversation = false;
        this.messages = [];
        this.rendered_messages = [];
      }
    },

    async setManual(conversation) {
      const resp = await Api.setConversationManual(conversation._id);
      if (!resp.error && resp.message) {
        conversation.is_manual = true;
      }
    },

    async changeConversationData() {
      const resp = await Api.changeConversationData(this.selectedConversation);
      if (!resp.error) {
        emitToastr("success", "Conversa atualizada");
        this.selectedConversation = {};
        this.messages = [];
        this.rendered_messages = [];
        this.showConversation = false;
      }
    },

    async transferToFlow() {
      if (!this.selectedConversation.flow) return emitToastr("error", "É necessário selecionar um fluxo antes de transferir");
      this.selectedConversation.chatbot_type = 'flow_chatbot';
      this.selectedConversation.is_manual = false;
      this.selectedConversation.start_flow = true;
      this.selectedConversation.last_flow_context = "";
      delete this.selectedConversation.chatbot;

      const resp = await Api.changeConversationData(this.selectedConversation);
      if (!resp.error) {
        emitToastr("success", "Conversa atualizada");
      }
    },

    async changeContactTags() {
      const resp = await Api.changeContactTags(this.selectedConversation.contact, this.selectedConversation._id);
      if (!resp.error) emitToastr("success", "Tags atualizadas");
    },

    clearFilters() {
      this.pre_filter = {
        text: '', changed: false, tags: [], departments: [],
        types: [
          { name: 'Chatbot', id: 'chatbot', icon: 'mdi-robot-outline' },
          { name: 'Humano', id: 'human', icon: 'mdi-face-agent' }
        ]
      };
      this.can_clear = false;
      this.buildFilter();
      //NOTE: OK
      this.getConversations(this.options);
      this.filter_sheet = false;
    },

    applyFilters() {
      this.selectedConversation = {};
      this.messages = [];
      this.rendered_messages = [];
      this.can_clear = true;
      this.buildFilter();
      //NOTE: OK
      this.getConversations(this.options);
      this.filter_sheet = false;
    },

    openFile(url) {
      window.open(url, "_blank");
    },

    async checkPhone() {
      this.new_conversation_jid = false;
      this.loading = true;
      const resp = await Api.checkPhone(this.country_code + this.new_conversation_phone);
      this.loading = false;
      if (!resp.error && resp.message) {
        this.new_conversation_jid = resp.message;
        this.startNewConversation();
      }

    },

    async startNewConversationDialog() {
      this.new_contact = null;
      this.contacts = [];
      this.new_conversation_contact_type = 'existing';
      this.new_conversation_phone = '';
      this.new_conversation_jid = false;
      this.newConversationDialog = true
    },

    async startNewConversation() {
      this.newConversationDialog = false;

      if (this.new_conversation_contact_type == 'new') {
        await Api.startNewConversation(this.new_conversation_jid, this.new_conversation_chip, this.new_conversation_department);
      }
      else {
        await Api.startNewConversation(this.new_contact.channel_contact_id, this.new_conversation_chip, this.new_conversation_department);
      }
    },

    openMedias() {
      this.dialogMedia = true;
    },

    openRecording() {
      this.dialogRecording = true;
    },

    updateMedias(message) {
      if (message.type == 'delete') {
        const i = this.medias.findIndex(m => m._id.toString() == message.media_id);
        if (i != -1) this.medias.splice(i, 1);
      }
      else {
        this.medias.push(message);
      }
    },

    async sendMedia(media) {
      this.dialogMedia = false;
      this.sendMessage(media);
    },

    async deleteMessage() {
      const resp = await Api.deleteConversationMessage(this.selectedChatItem.platform_message_id, this.selectedConversation.contact?.phone, this.selectedConversation.chip);
      if (!resp.error && resp.message == 'ok') {
        this.selectedChatItem.deleted = true;
        this.$forceUpdate();
      }
    },

    showImage(media_url) {
      this.media_url = media_url;
      this.imageViewerDialog = true;
    },

    openContact(contact_id) {
      this.selectedContact = contact_id;
      this.contactDrawer = true;
    },

    getItemText(item) {
      return item.override_name || item.name;
    },

    async toggleNotificationSoundConfig() {
      let user_config = this.currentUser.config || { disable_notification_sound: false };
      user_config.disable_notification_sound = !user_config.disable_notification_sound;
      await this.$api.updateUserConfig(user_config);
      emitToastr("success", "Configuração alterada.");
    },

    async searchContacts(val) {
      this.loading = true;
      if (timeout) clearTimeout(timeout);

      timeout = setTimeout(async () => {
        this.options.page = 1;
        this.loading = true;
        const resp = await Api.getContactsByUser({ itemsPerPage: 10, query: val });
        if (!resp.error && resp.message) {
          this.contacts = resp.message.data;
          this.loading = false;
        }
        this.loading = false;
      }, 250);
    },

    async summarizeMessages() {
      try {
        if (this.summary_ai_loading) emitToastr("error", "Resumo em processamento. Por favor aguarde...");

        // eslint-disable-next-line
        if (tianji) tianji.track('ai_summary', {});

        let msgs = this.messages.filter(m => m.type === 'text' || m.type === 'audio');
        if (msgs.length > 30) msgs = msgs.slice(-30);

        let simplifiedConversation = '';

        msgs.forEach(m => {
          simplifiedConversation += `${m.origin === 'customer' ? 'Contato' : this.users[m.user] || 'Chatbot'}: ${m.message}\n`
        });

        this.summary_ai_loading = true;
        const resp = await Api.summarizeMessages(this.selectedConversation._id, simplifiedConversation);
        this.summary_ai_loading = false;

        if (!resp.error && resp.message) {
          emitToastr("success", `Conversa resumida. Saldo mensal disponível para novos resumos: ${resp.balance}`);
        }
      }
      catch (err) {
        emitToastr("error", "Erro ao resumir mensagens");
      }
    },

    async createAIFollowUp() {
      try {
        if (this.follow_up_ai_loading) emitToastr("error", "Follow-up em processamento. Por favor aguarde...");

        // eslint-disable-next-line
        if (tianji) tianji.track('ai_follow_up', {});

        let msgs = this.messages.filter(m => m.type === 'text' || m.type === 'audio');
        if (msgs.length > 15) msgs = msgs.slice(-15);

        let simplifiedConversation = '';

        msgs.forEach(m => {
          simplifiedConversation += `${m.origin === 'customer' ? 'Contato' : this.users[m.user] || 'Chatbot'}: ${m.message}\n`
        });

        this.follow_up_ai_loading = true;
        const resp = await Api.createAIFollowUp(this.selectedConversation._id, simplifiedConversation);
        this.follow_up_ai_loading = false;

        if (!resp.error && resp.message) {
          emitToastr("success", `Follow-up criado. Saldo mensal disponível para novos follow-ups: ${resp.balance}`);
          this.text = resp.message.follow_up;
        }
      }
      catch (err) {
        emitToastr("error", "Erro ao resumir mensagens");
      }
    }
  },
};
</script>

<style scoped lang="scss">
.custom-page {
  height: auto;
  max-height: 100%;
  overflow: hidden;
  padding: 0 !important;
}

.conversation-list {
  height: calc(100vh - 130px);
  overflow-y: scroll;
  scrollbar-width: thin !important;
  scrollbar-color: rgba(206, 206, 206, 0.322) transparent !important;
}

.filter-bar {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.filter-item {
  font-size: 14px;
}

.conversation-container {
  background-color: var(--v-gray75-base) !important;
  position: relative;
  height: calc(100vh - 250px);
  overflow: hidden;
  scrollbar-width: thin !important;
  scrollbar-color: rgba(206, 206, 206, 0.322) transparent !important;

}

.contact-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.contact-info {
  color: var(--v-gray600-base) !important;
  display: flex;
  flex-direction: column;
  margin-left: 14px;
}

.contact-name {
  font-size: 18px;
  font-weight: 600;
}

.contact-phone {
  font-size: 14px;
}

.messages-background {
  background-color: var(--v-gray75-base) !important;
  border-radius: 10px;
  border: 1px solid var(--v-gray200-base);
  margin: 5px;
  margin-top: 15px;
}

.messages-app-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  background-color: white;
  margin-bottom: 4px;
}

.messages-container {
  background-color: var(--v-gray75-base) !important;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: calc(100vh - 307px);
  font-size: 14px;
  scrollbar-width: thin !important;
  scrollbar-color: rgba(206, 206, 206, 0.322) transparent !important;
}

.chat-item {
  background-color: white;
  border: 1px solid var(--v-gray100-base);
  color: var(--v-gray600-base);
  max-width: 400px;
  padding: 8px;
  padding-bottom: 4px;
  margin-top: 6px;
  margin-bottom: 6px;
  border-radius: 12px;
  white-space: pre-line;
  word-wrap: break-word;
}

.chatbot-message {
  background-color: var(--v-green100-base);
  border: 1px solid var(--v-green200-base);
  font-weight: 500;
  color: var(--v-green900-base);
  align-self: flex-end;
  word-break: break-word;
}

.customer-message {
  background-color: #ffffff;
  font-weight: 500;
  align-self: flex-start;
  word-break: break-word;
}

.deleted-message {
  background-color: #ffdfdf !important;
  border: 1px solid #ffc9c9
}

.summary-message {
  background-color: #f7cdff !important;
  border: solid 1px #ba68c8 !important
}

.message-time {
  text-align: right;
  font-size: 11px;
}

.command-bar {
  height: 80px;
  position: fixed;
  display: flex;
  bottom: 10px;
  margin-right: 10px;
  background-color: white;
  border: 1px solid var(--v-gray50-base);
  border-radius: 12px;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
}

.one-line {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.video-container {
  width: 100%;
  max-height: 50vh;
  border-radius: 6px;
}

.reply-container {
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  font-style: italic;
  font-size: 12px;
}

::v-deep .v-dialog {
  scrollbar-width: thin !important;
  scrollbar-color: rgba(206, 206, 206, 0.322) transparent !important;
}

#exampleInputEmoji {
  position: absolute;
  transform: translateY(-200px);
  z-index: 999;
}

@media only screen and (max-width: 960px) {
  .messages-background {
    margin: 0px;
  }

  .conversation-container {
    height: calc(100vh - 120px);
  }

  .conversation-list {
    height: calc(100vh - 110px);
  }

  .contact-info {
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .contact-name {
    font-size: 15px !important;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .messages-container {
    height: calc(100vh - 190px);
  }
}
</style>