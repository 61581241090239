import Vue from "vue";

import { emitToastr } from "@/lib/Utils";
import Api from "@/lib/Api";
import { io } from "socket.io-client";

import { format, parseISO } from "date-fns";

const { customAlphabet } = require('nanoid');
const nanoid = customAlphabet('1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ', 28);

const audio = new Audio('/notifications/1.mp3');

Vue.prototype.$toast = emitToastr;
Vue.prototype.$api = Api;
Vue.prototype.$io = io;

Vue.prototype.$saveImage = (media_url) => {
    const link = document.createElement('a');
    link.href = media_url;
    link.download = 'imagem.jpg';
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

Vue.prototype.$playNotificationSound = () => {
    try {
        audio.play();
    }
    catch (err) {
        return;
    }
}

Vue.prototype.$newID = (type = '') => {
    try {
        return `${nanoid()}${type}`;
    }
    catch (err) {
        return "";
    }
}

Vue.prototype.$notify = (title, body) => {
    if (!("Notification" in window)) {
        return;
    } else if (Notification.permission === "granted") {
        new Notification(title, { body, icon: "favicon.ico", silent: true });
    } else if (Notification.permission !== "denied") {
        Notification.requestPermission().then(function (permission) {
            if (permission === "granted") {
                new Notification(title, { body, icon: "favicon.ico", silent: true });
            }
        });
    }
};




Vue.prototype.$date = {
    defaultFormat(value) {
        return format(new Date(value), "dd/MM/yyyy HH:mm");
    },
    format(value, pattern) {
        const isoDate = parseISO(value)

        return format(isoDate, pattern);
    },
    smallFormat(value) {
        if (!value) return;
        if (format(new Date(), "dd/MM/yyyy") == format(new Date(value), "dd/MM/yyyy")) {
            return format(new Date(value), "HH:mm");
        }
        else {
            return format(new Date(value), "dd/MM/yyyy HH:mm");
        }
    },
}


Vue.prototype.$utils = {
    pickFile(options) {
        return new Promise((resolve) => {
            const input = document.createElement('input');

            input.type = 'file';
            input.accept = options.accept || '*/*';

            input.onchange = (e) => {
                const file = e.target.files[0];

                resolve(file);
            }

            input.click();
        })
    },
    copy(payload) {
        return JSON.parse(JSON.stringify(payload));
    },

    formatPhone(phone_string) {
        if (!phone_string) return "";
        phone_string = phone_string.replace(/\D/g, '');

        // Divide o número em partes: DDI (55), DDD, e o restante do telefone
        const ddi = phone_string.slice(0, 2); // "55"
        const ddd = phone_string.slice(2, 4); // Exemplo: "11"
        const telefone = phone_string.slice(4);

        // Formata o telefone com base no número de dígitos
        const telefoneFormatado = telefone.length === 9
            ? `${telefone.slice(0, 5)}-${telefone.slice(5)}` // 9 dígitos (ex: 12345-6789)
            : `${telefone.slice(0, 4)}-${telefone.slice(4)}`; // 8 dígitos (ex: 1234-5678)

        return `+${ddi} (${ddd}) ${telefoneFormatado}`;
    },

    formatNumber(num, type = '') {
        if (!num) return '0';
        if (num >= 1_000_000) {
            return `${type} ${(Math.round(num / 100000) / 10)} M`;
        } else if (num >= 1000) {
            return `${type} ${(Math.round(num / 100) / 10)} k`;
        } else {
            return `${type} ${Math.round(num * 10) / 10}`;
        }
    }
}

Vue.prototype.$rules = {
    required() {
        return (value) => !!value || 'Campo obrigatório';
    }
}

Vue.prototype.$renderChatbotText = (string, object) => string.replaceAll(/\{([^}]+)\}/gi, (_, a) => {
    return a.split(/\.|\[|\]/).filter(p => p !== '').reduce((obj, prop) => {
        return obj ? obj[prop] : "Não tenho essa informação no momento";
    }, object);
});